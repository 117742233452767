import React from 'react';
import PropTypes from 'prop-types';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import {
  createErrorDetails, getErrorMessage, getErrorSimplifiedDisplay, getErrorTitle, isErrorDisplaySimplified,
} from '../../../dmpconnect/errors';
import ErrorDetails from './ErrorDetails';
import Alert from './Alert';
import { objectWithoutTheseKeys } from '../../../dmpconnect/utils/dataUtils';
import GetLogs from '../Logs/GetLogsButton';

export default function ErrorMessage({
  error, title, message, showDetails,
}) {
  const [details, toggleDetails] = React.useState(false);
  return (
    <div>
      <Alert type="danger">
        <div className="typography-big-text-title mb-2">
          {title || getErrorTitle(error)}
        </div>
        <>
          {(
            message
            || error.customErrorMessage
            || (isErrorDisplaySimplified(error) ? getErrorSimplifiedDisplay(error) : getErrorMessage(error))
          )}
        </>
        {showDetails && (
          <div className="mt-2">
            <Button
              variant="outline-danger"
              size="sm"
              onClick={() => toggleDetails(true)}
            >
              Afficher les détails de l&apos;erreur
            </Button>
          </div>
        )}
      </Alert>
      {showDetails && (
        <Modal show={details} onHide={() => toggleDetails(false)}>
          <Modal.Header closeButton>Erreur détaillée</Modal.Header>
          <Modal.Body>
            <ErrorDetails details={[createErrorDetails('erreur', objectWithoutTheseKeys(
              { detailsType: 'error', ...error },
              ['customErrorMessage'],
            ))]}
            />
          </Modal.Body>
          <Modal.Footer className="d-flex justify-content-between">
            <GetLogs
              onOpen={() => toggleDetails(false)}
              error={objectWithoutTheseKeys(
                { detailsType: 'error', ...error },
                ['customErrorMessage'],
              )}
            />
            <Button variant="outline-secondary" onClick={() => toggleDetails(false)}>Fermer</Button>
          </Modal.Footer>
        </Modal>
      )}
    </div>
  );
}

ErrorMessage.defaultProps = {
  title: '',
  message: '',
  showDetails: false,
};

ErrorMessage.propTypes = {
  error: PropTypes.object.isRequired,
  message: PropTypes.node,
  title: PropTypes.string,
  showDetails: PropTypes.bool,
};
