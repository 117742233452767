import React, { Component } from 'react';

import {
  Button, Modal,
} from 'react-bootstrap';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Collapse from 'react-bootstrap/Collapse';
import { getApiType, getCurrentPathname } from 'dmpconnectjsapp-base/helpers/accessors';
import { clearModalError, logout } from '../../dmpconnect/actions';
import ErrorDetails from '../Common/Message/ErrorDetails';
import {
  getErrorDescription,
  getErrorMessage,
  getErrorSimplifiedDisplay,
  isErrorDisplaySimplified,
} from '../../dmpconnect/errors';
import { errorActions, errorActionsMessages, executeErrorAction } from '../../dmpconnect/errors/errorActions';
import { errorSeverities } from '../../dmpconnect/errors/errorConfiguration';
import GetLogs from '../Common/Logs/GetLogsButton';
import { API_TYPES } from '../../dmpconnect/constants';

class ErrorModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showDetails: false,
      show: true,
    };
    this.handleClose = this.handleClose.bind(this);
  }

  getErrorDescription(modalError) {
    if (modalError) {
      return getErrorDescription(modalError.error);
    }
    return null;
  }

  getHeaderClass(errorDescription) {
    const { modalError } = this.props;

    if (modalError.forceAction && modalError.forceAction === errorActions.NONE) {
      return 'bg-warning';
    }

    return errorDescription.severity === errorSeverities.HIGH_SEVERITY ? 'bg-danger' : 'bg-warning';
  }

  handleClose(logError) {
    const { dispatch, modalError } = this.props;

    const error = modalError || logError;

    if (error.forceReconnect === true) {
      dispatch(logout());
    } else if (modalError.forceAction) {
      executeErrorAction({ action: modalError.forceAction }, dispatch);
    } else {
      const errorDescription = this.getErrorDescription(error);
      if (errorDescription === null) return;
      executeErrorAction(errorDescription, dispatch);
    }
    dispatch(clearModalError());
    this.setState({ show: true });
  }

  toggleDetails() {
    this.setState(prevState => ({
      showDetails: !prevState.showDetails,
    }));
  }

  render() {
    const { modalError, apiType } = this.props;
    const { showDetails, show } = this.state;
    // const show = modalError !== null;
    const errorDescription = this.getErrorDescription(modalError);
    const showDetailsToggleClass = showDetails ? 'dropup' : 'dropdown';
    const showLogs = apiType !== API_TYPES.REST && ((modalError && modalError.logs) || (errorDescription && errorDescription.logs));
    return (
      <>
        {modalError
        && (
          <Modal
            className="error-modal"
            show={show}
            onHide={() => this.handleClose()}
            centered
            backdrop="static"
          >
            <Modal.Header className={this.getHeaderClass(errorDescription)} closeButton>
              <Modal.Title as="h5" className="text-white">{errorDescription.title}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div style={{ whiteSpace: 'pre-wrap' }}>
                {(
                  errorDescription.customErrorMessage
                  || (
                    isErrorDisplaySimplified(modalError.error)
                      ? getErrorSimplifiedDisplay(modalError.error)
                      : getErrorMessage(modalError.error)
                  )
                )}
              </div>
              {errorDescription.showDetails
              && (
                <div>
                  <div className="d-flex">
                    <div className={`btn-group ml-auto ${showDetailsToggleClass} `}>
                      <button
                        type="button"
                        className="btn btn-outline dropdown-toggle ml-auto"
                        onClick={() => this.toggleDetails()}
                      >
                        Détails
                      </button>
                    </div>
                  </div>
                  <Collapse in={showDetails}>
                    <div className="mt-1 overflow-auto" style={{ width: '100%', height: '0px' }}>
                      <div className="mb-2">Merci de transmettre au service technique les informations ci-dessous.</div>
                      <ErrorDetails
                        details={modalError.details}
                      />
                    </div>
                  </Collapse>
                </div>
              )
              }
            </Modal.Body>
            <Modal.Footer className={showLogs !== false ? 'd-flex justify-content-between' : ''}>
              {showLogs !== false && (
              <GetLogs
                onOpen={() => this.setState({ show: false })}
                error={modalError.details}
                onClose={() => this.handleClose(modalError)}
              />
              )}
              <Button variant="outline-secondary" onClick={() => this.handleClose()}>
                {modalError.forceReconnect === true ? (
                  errorActionsMessages[errorActions.CLOSE_SESSION]
                ) : (
                  errorActionsMessages[modalError.forceAction || errorDescription.action]
                )}
              </Button>
            </Modal.Footer>
          </Modal>
        )}
      </>
    );
  }
}

ErrorModal.propTypes = {
  modalError: PropTypes.object,
  dispatch: PropTypes.func.isRequired,
  apiType: PropTypes.string.isRequired,
};

ErrorModal.defaultProps = {
  modalError: {},
};

function mapStateToProps(state) {
  const { dmpconnectApplication: { modalError } } = state;
  return {
    currentPathName: getCurrentPathname(state),
    modalError,
    apiType: getApiType(state),
  };
}

const connectedErrorModal = connect(mapStateToProps)(ErrorModal);

export default connectedErrorModal;
