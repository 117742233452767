import moment from 'moment';
import {
  API_TYPES as connectorApiTypes,
  dmpconnectActionConstants,
  dmpconnectConfigurationActionConstants as connectorConfigActionConstants, mssActionConstants,
} from 'dmpconnectjsapp-base/constants';

export const API_TYPES = connectorApiTypes;
export const dmpconnectConfigurationActionConstants = {
  ...connectorConfigActionConstants,
  DMPC_SET_PERSIST_APP_CONFIGURATION: 'DMPC_SET_PERSIST_APP_CONFIGURATION',
  DMPC_RESET_PERSIST_APP_CONFIGURATION_FROM_ENV: 'DMPC_RESET_PERSIST_APP_CONFIGURATION_FROM_ENV',
  DMPC_RESET_ALL_CONFIGURATION_FROM_ENV: 'DMPC_RESET_ALL_CONFIGURATION_FROM_ENV',

  SET_USER_PREFERENCE: 'SET_USER_PREFERENCE',
  SET_OPENID_CONFIG: 'SET_OPENID_CONFIG',
  RESET_OPENID_CONFIG_FROM_ENV: 'RESET_OPENID_CONFIG_FROM_ENV',

  DMPC_SET_PERSIST_APP_MSS_SYNCMESSAGES: 'DMPC_SET_PERSIST_APP_MSS_SYNCMESSAGES',
  DMPC_SET_PERSIST_APP_MSS_FOLDERS: 'DMPC_SET_PERSIST_APP_MSS_FOLDERS',
  DMPC_SET_PERSIST_APP_MESSAGE_READ: 'DMPC_SET_PERSIST_APP_MESSAGE_READ',
  DMPC_SET_PERSIST_APP_MESSAGE_MOVED: 'DMPC_SET_PERSIST_APP_MESSAGE_MOVED',
  DMPC_SET_PERSIST_APP_MESSAGE_DELETED: 'DMPC_SET_PERSIST_APP_MESSAGE_DELETED',
};

export const dmpconnectAccessibleDmpListActionsConstants = {
  UPDATE_LAST_ACCESS: 'UPDATE_LAST_ACCESS',
  IMPORT_ACCESSIBLE_DMPS_LIST: 'IMPORT_ACCESSIBLE_DMPS_LIST',
  RESET_LIST: 'RESET_LIST',
  ASSOCIATE_INSI_IDENTITY: 'ASSOCIATE_INSI_IDENTITY',
  DISSOCIATE_INSI_IDENTITY: 'DISSOCIATE_INSI_IDENTITY',
};

export const dmpconnectMonitoringActionConstants = {
  DMPC_VITALE_MONITORING_START: 'DMPC_VITALE_MONITORING_START',
  DMPC_VITALE_MONITORING_STOP: 'DMPC_VITALE_MONITORING_STOP',
  DMPC_VITALE_MONITORING_UPDATE: 'DMPC_VITALE_MONITORING_UPDATE',

  DMPC_CPX_MONITORING_START: 'DMPC_CPX_MONITORING_START',
  DMPC_CPX_MONITORING_STOP: 'DMPC_CPX_MONITORING_STOP',
  DMPC_CPX_MONITORING_UPDATE: 'DMPC_CPX_MONITORING_UPDATE',
};

export const dmpconnectRemoteActionConstants = {
  DMPC_REMOTE_SEND_COMMAND: 'DMPC_REMOTE_SEND_COMMAND',
  DMPC_REMOTE_COMMAND_START: 'DMPC_REMOTE_COMMAND_START',
  DMPC_REMOTE_COMMAND_SUCCESS: 'DMPC_REMOTE_COMMAND_SUCCESS',
  DMPC_REMOTE_COMMAND_FAILURE: 'DMPC_REMOTE_COMMAND_FAILURE',

  DMPC_CLEAR_REMOTE_SECTION: 'DMPC_CLEAR_REMOTE_SECTION',

  START_REMOTE_CONTROL_PROCESS: 'START_REMOTE_CONTROL_PROCESS',
  STOP_REMOTE_CONTROL_PROCESS: 'STOP_REMOTE_CONTROL_PROCESS',
  DMPC_REMOTE_MONITORING_STATUS: 'DMPC_REMOTE_MONITORING_STATUS',
  DMPC_REMOTE_MONITORING_START: 'DMPC_START_REMOTE_MONITORING',
  DMPC_REMOTE_MONITORING_STOP: 'DMPC_STOP_REMOTE_MONITORING',
  DMPC_REMOTE_MONITORING_RECEIVED_REQUEST: 'DMPC_RECEIVED_REQUEST_REMOTE_MONITORING',
  DMPC_REMOTE_MONITORING_SEND_RESPONSE_: 'DMPC_SEND_RESPONSE_REMOTE_MONITORING',
  DMPC_REMOTE_EXPORT_DOCUMENT: 'DMPC_REMOTE_EXPORT_DOCUMENT',

  DMPC_REMOTE_GET_CLOSE_DMP_CONSENT: 'DMPC_REMOTE_GET_CLOSE_DMP_CONSENT',
  DMPC_REMOTE_ANSWER_CLOSE_DMP_CONSENT: 'DMPC_REMOTE_ANSWER_CLOSE_DMP_CONSENT',

  DMPC_REMOTE_GET_OPEN_DMP_CONSENT: 'DMPC_REMOTE_GET_OPEN_DMP_CONSENT',
  DMPC_REMOTE_ANSWER_OPEN_DMP_CONSENT: 'DMPC_REMOTE_ANSWER_OPEN_DMP_CONSENT',

  DMPC_REMOTE_GET_CREATE_DMP_CONSENT: 'DMPC_REMOTE_GET_CREATE_DMP_CONSENT',
  DMPC_REMOTE_ANSWER_CREATE_DMP_CONSENT: 'DMPC_REMOTE_ANSWER_CREATE_DMP_CONSENT',
  DMPC_REMOTE_CREATE_DMP_SHOW_FORM: 'DMPC_REMOTE_CREATE_DMP_SHOW_FORM',
  DMPC_REMOTE_CREATE_DMP_HIDE_FORM: 'DMPC_REMOTE_CREATE_DMP_HIDE_FORM',

  DMPC_REMOTE_GET_REACTIVATE_DMP_CONSENT: 'DMPC_REMOTE_GET_REACTIVATE_DMP_CONSENT',
  DMPC_REMOTE_ANSWER_REACTIVATE_DMP_CONSENT: 'DMPC_REMOTE_ANSWER_REACTIVATE_DMP_CONSENT',
  DMPC_REMOTE_REACTIVATE_DMP_SHOW_FORM: 'DMPC_REMOTE_REACTIVATE_DMP_SHOW_FORM',
  DMPC_REMOTE_REACTIVATE_DMP_HIDE_FORM: 'DMPC_REMOTE_REACTIVATE_DMP_HIDE_FORM',

  DMPC_REMOTE_SUBMIT_DOC: 'DMPC_REMOTE_SUBMIT_DOC',
  DMPC_REMOTE_SUBMIT_DOC_INS: 'DMPC_REMOTE_SUBMIT_DOC_INS',
  DMPC_REMOTE_SUBMIT_DOC_REFUSE: 'DMPC_REMOTE_SUBMIT_DOC_REFUSE',

  DMPC_REMOTE_EXPORT_EMAIL: 'DMPC_REMOTE_EXPORT_EMAIL',
  DMPC_REMOTE_EXPORT_EMAIL_DONE: 'DMPC_REMOTE_EXPORT_EMAIL_DONE',
  DMPC_REMOTE_MSS_SEND_MESSAGE_REFUSED: 'DMPC_REMOTE_MSS_SEND_MESSAGE_REFUSED',

  DMPC_REMOTE_EXPORT_VITALE_DATA: 'DMPC_REMOTE_EXPORT_VITALE_DATA',
};

export const dmpconnectVirtualPrinterActionConstants = {
  DMPC_VIRTUAL_PRINTER_SEND_COMMAND: 'DMPC_VIRTUAL_PRINTER_SEND_COMMAND',
  DMPC_VIRTUAL_PRINTER_COMMAND_SUCCESS: 'DMPC_VIRTUAL_PRINTER_COMMAND_SUCCESS',
  DMPC_VIRTUAL_PRINTER_COMMAND_FAILURE: 'DMPC_VIRTUAL_PRINTER_COMMAND_FAILURE',
  DMPC_VIRTUAL_PRINTER_CLEAR_SECTION: 'DMPC_VIRTUAL_PRINTER_CLEAR_SECTION',

  DMPC_VIRTUAL_PRINTER_MONITORING_START: 'DMPC_VIRTUAL_PRINTER_MONITORING_START',
  DMPC_VIRTUAL_PRINTER_MONITORING_STOP: 'DMPC_VIRTUAL_PRINTER_MONITORING_STOP',
  DMPC_VIRTUAL_PRINTER_MONITORING_RECEIVED_FILE: 'DMPC_VIRTUAL_PRINTER_MONITORING_RECEIVED_FILE',
  DMPC_VIRTUAL_PRINTER_GET_FILES: 'DMPC_VIRTUAL_PRINTER_GET_FILES',
  DMPC_VIRTUAL_PRINTER_FLUSH_FILES: 'DMPC_VIRTUAL_PRINTER_FLUSH_FILES',
  DMPC_VIRTUAL_PRINTER_TOGGLE_OVERLAY: 'DMPC_VIRTUAL_PRINTER_TOGGLE_OVERLAY',
  DMPC_VIRTUAL_PRINTER_SEND_DOCUMENT: 'DMPC_VIRTUAL_PRINTER_SEND_DOCUMENT',
  DMPC_VIRTUAL_PRINTER_SELECT_INS: 'DMPC_VIRTUAL_PRINTER_SELECT_INS',
};

export const dmpconnectPDFsActionConstants = {
  DMPC_SET_DMP_PDF_ACK: 'DMPC_SET_DMP_PDF_ACK',
  DMPC_SET_DMP_PDF_OTP: 'DMPC_SET_DMP_PDF_OTP',
  DMPC_SET_DMP_PDF_CREATE: 'DMPC_SET_DMP_PDF_CREATE',
  DMPC_SET_DMP_PDF_RESET: 'DMPC_SET_DMP_PDF_RESET',
};

export const dpActionsConstants = {
  DMPC_GET_DP_STATUS: 'DMPC_GET_DP_STATUS',
  DMPC_GET_RECORD_STATUS: 'DMPC_GET_RECORD_STATUS',
};

export const dmpconnectApplicationActionConstants = {
  HARD_RESET: 'HARD_RESET',
  DMPS_SET_URL_PROCESSED: 'DMPS_SET_URL_PROCESSED',
  DMPC_DCPARAMS_UNREGISTERED: 'DMPC_DCPARAMS_UNREGISTERED',
  DMPC_DCPARAMS_REGISTERED: 'DMPC_DCPARAMS_REGISTERED',
  DMPC_INIT_APPLICATION: 'DMPC_INIT_APPLICATION',
  DMPC_RESET_APPLICATION: 'DMPC_RESET_APPLICATION',
  DMPC_INIT_APPLICATION_FAILURE: 'DMPC_INIT_APPLICATION_FAILURE',
  DMPC_INIT_APPLICATION_SUCCESS: 'DMPC_INIT_APPLICATION_SUCCESS',
  DMPC_SET_MODAL_ERROR: 'DMPC_SET_MODAL_ERROR',
  DMPC_CLEAR_MODAL_ERROR: 'DMPC_CLEAR_MODAL_ERROR',
  DMPC_SET_DMP_SEARCH_PANEL_OPENED: 'DMPC_SET_DMP_SEARCH_PANEL_OPENED',
  DMPC_CLOSE_MANAGE_DMP_MODAL: 'DMPC_CLOSE_MANAGE_DMP_MODAL',
  DMPC_SET_INTEROP_CODES: 'DMPC_SET_INTEROP_CODES',
  DMPC_SET_DMP_PARAMETERS: 'DMPC_SET_DMP_PARAMETERS',
  DMPC_SET_SOCKET: 'DMPC_SET_SOCKET',
  DMPC_SET_CONNECTOR_OK: 'DMPC_SET_CONNECTOR_OK',
  DMPC_SET_CONNECTOR_KO: 'DMPC_SET_CONNECTOR_KO',
  DMPC_SET_CONNECTOR_CONFIG_OK: 'DMPC_SET_CONNECTOR_CONFIG_OK',
  DMPC_SET_CONNECTOR_CONFIG_KO: 'DMPC_SET_CONNECTOR_CONFIG_KO',
  DMPC_SET_SESSION_OK: 'DMPC_SET_SESSION_OK',
  DMPC_SET_SESSION_KO: 'DMPC_SET_SESSION_KO',
  DMPC_SET_CONNECTOR_VERSION_KO: 'DMPC_SET_CONNECTOR_VERSION_KO',
  DMPC_SET_LICENSE_STATUS: 'DMPC_SET_LICENSE_STATUS',
  DMPC_START_VIRTUAL_PRINTER: 'DMPC_START_VIRTUAL_PRINTER',
  DMPC_DASHBOARD_INIT: 'DMPC_DASHBOARD_INIT',
  DMPC_DASHBOARD_INIT_OK: 'DMPC_DASHBOARD_INIT_OK',
  DMPC_CHECK_CONNECTOR: 'DMPC_CHECK_CONNECTOR',

  DMPC_LICENSE_CHECK: 'DMPC_LICENSE_CHECK',
  DMPC_LICENSE_AFTER_FORM_OK: 'DMPC_LICENSE_AFTER_FORM_OK',
  DMPC_LICENSE_CHECK_LOADING: 'DMPC_LICENSE_CHECK_LOADING',

  DMPC_DP_POPUP_OPENED: 'DMPC_DP_POPUP_OPENED',
  DMPC_ALD_POPUP_OPENED: 'DMPC_ALD_POPUP_OPENED',
  DMPC_MSS_POPUP_OPENED: 'DMPC_MSS_POPUP_OPENED',
  DMPC_READ_VITALE_PROCESS: 'DMPC_READ_VITALE_PROCESS',

  DMPC_MSS_RESET_EMAIL_CONTENT: 'DMPC_MSS_RESET_EMAIL_CONTENT',
  DMPC_MSS_SET_EMAIL_CONTENT: 'DMPC_MSS_SET_EMAIL_CONTENT',
  DMPC_MSS_ADD_EMAIL_ATTACHEMENT: 'DMPC_MSS_ADD_EMAIL_ATTACHEMENT',

  DMPC_SAAS_TOKEN_VALIDATED: 'DMPC_SAAS_TOKEN_VALIDATED',

  DMPC_SET_MODAL_CONFIRMATION: 'DMPC_SET_MODAL_CONFIRMATION',
  DMPC_SET_MODAL_INFORMATION: 'DMPC_SET_MODAL_INFORMATION',

  DMPC_SHOW_UPLOAD_DOCUMENT_MODAL: 'DMPC_SHOW_UPLOAD_DOCUMENT_MODAL',

  DMPC_EXPORT_CDA_CONTENT: 'DMPC_EXPORT_CDA_CONTENT',
  DMPC_SAVE_IN_PERSISTANT_DATA: 'DMPC_SAVE_IN_PERSISTANT_DATA',

  DMPC_SET_BACK_PAGE: 'DMPC_SET_BACK_PAGE',

  DMPC_GET_INS_FROM_VITALE_CARD: 'DMPC_GET_INS_FROM_VITALE_CARD',
  DMPC_CHECK_AND_GET_INS: 'DMPC_CHECK_AND_GET_INS',
  SET_VITALE_CARD_SERIAL_NUMBER: 'SET_VITALE_CARD_SERIAL_NUMBER',

  DMPC_UPDATE_CPX_ACCESS_TOKEN: 'DMPC_UPDATE_CPX_ACCESS_TOKEN',

  DMPC_API_LOGIN_CHECK_VALUES: 'DMPC_API_LOGIN_CHECK_VALUES',

  SHOW_LOADING_BACKDROP: 'SHOW_LOADING_BACKDROP',

  SET_IS_FIRST_LOGIN: 'SET_IS_FIRST_LOGIN',

  INIT_USER_PREFERENCES: 'INIT_USER_PREFERENCES',
  SAVE_USER_DATA_IN_PERSISTANT_DATA: 'SAVE_USER_DATA_IN_PERSISTANT_DATA',

  GET_LOG_LEVELS: 'GET_LOG_LEVELS',
  RESET_LOG_LEVELS: 'RESET_LOG_LEVELS',
  SET_LOG_LEVELS: 'SET_LOG_LEVELS',
  GET_LOGS: 'GET_LOGS',
  GOT_LOGS: 'GOT_LOGS',
  SHOW_LOGS_POPUP: 'SHOW_LOGS_POPUP',
  SET_SUBMIT_ENGINE: 'SET_SUBMIT_ENGINE',
  SET_LOGIN_TAB: 'SET_LOGIN_TAB',

  UPDATE_USER_DMP_ACCESS_AUTHORIZATION: 'UPDATE_USER_DMP_ACCESS_AUTHORIZATION',

  SET_FORCED_CPX_READER: 'SET_FORCED_CPX_READER',
  SET_FORCED_VITALE_READER: 'SET_FORCED_VITALE_READER',

  SET_IS_MOBILE_LAYOUT: 'SET_IS_MOBILE_LAYOUT',
  SET_MOBILE_PREVIEW_ACTIVE: 'SET_MOBILE_PREVIEW_ACTIVE',
};

export const dmpconnectFiltersActionConstants = {
  DMPC_SET_ALL_USERS_FILTERS: 'DMPC_SET_ALL_USERS_FILTERS',
  DMPC_SET_USER_FILTER: 'DMPC_SET_USER_FILTER',
  DMPC_SET_CURRENT_FILTER: 'DMPC_SET_CURRENT_FILTER',
};
export const newsActionConstants = {
  DMPC_SET_USER_NEWS: 'DMPC_SET_USER_NEWS',
};

export const mssActions = {
  ...mssActionConstants,
  DOWNLOAD_ALL_ATTACHMENTS: 'DOWNLOAD_ALL_ATTACHMENTS',
  DOWNLOAD_ALL_ATTACHMENTS_ZIPPED: 'DOWNLOAD_ALL_ATTACHMENTS_ZIPPED',
  SET_MSS_SEARCHED_MESSAGE_IDS: 'SET_MSS_SEARCHED_MESSAGE_IDS',
  DELETE_MSS_FOLDER: 'DELETE_MSS_FOLDER',
};

export const dmpconnectUserActionConstants = {
  DMPC_LOGIN: 'DMPC_LOGIN',
  DMPC_LOGOUT: 'DMPC_LOGOUT',
  DMPC_LOGIN_SUCCESS: 'DMPC_LOGIN_SUCCESS',
  DMPC_LOGOUT_SUCCESS: 'DMPC_LOGOUT_SUCCESS',
  DMPC_SET_LOGIN_REFERER: 'DMPC_SET_LOGIN_REFERER',
  DMPC_SET_FIND_DOCUMENTS_PARAM: 'DMPC_SET_FIND_DOCUMENTS_PARAM',
  DMPC_SET_FIND_DOCUMENTS_PARAMS: 'DMPC_SET_FIND_DOCUMENTS_PARAMS',
  DMPC_SET_FIND_DOCUMENTS_PARAM_CATEGORIES: 'DMPC_SET_FIND_DOCUMENTS_PARAM_CATEGORIES',
  DMPC_SET_FIND_DOCUMENTS_OPENED_CATEGORIES: 'DMPC_SET_FIND_DOCUMENTS_OPENED_CATEGORIES',
  DMPC_RESET_FIND_DOCUMENTS_PARAMS: 'DMPC_RESET_FIND_DOCUMENTS_PARAMS',
  DMPC_SET_FIND_PATIENTS_PARAMS: 'DMPC_SET_FIND_PATIENTS_PARAMS',
  DMPC_SET_FIND_PATIENTS_INS: 'DMPC_SET_FIND_PATIENTS_INS',
  DMPC_CLEAR_LOGIN_REFERER: 'DMPC_CLEAR_LOGIN_REFERER',
  DMPC_SET_FORCE_SECRET_CONNEXION: 'DMPC_SET_FORCE_SECRET_CONNEXION',
  DMPC_REMOVE_DOCUMENT_FROM_CACHE: 'DMPC_REMOVE_DOCUMENT_FROM_CACHE',
  DMPC_INIT_DOCUMENTS_CACHE: 'DMPC_INIT_DOCUMENTS_CACHE',
  DMPC_UNSET_NEW_DOCUMENT_STATUS: 'DMPC_UNSET_NEW_DOCUMENT_STATUS',
  DMPC_MARK_ALL_DOCS_AS_READ: 'DMPC_MARK_ALL_DOCS_AS_READ',
  DMPC_UPDATE_DOCUMENT_NEXT_VERSION: 'DMPC_UPDATE_DOCUMENT_NEXT_VERSION',
  DMPC_UPDATE_DOCUMENTS_NEXT_VERSION: 'DMPC_UPDATE_DOCUMENTS_NEXT_VERSION',
  DMPC_SET_LOGIN_STEP_TWO: 'DMPC_SET_LOGIN_STEP_TWO',
  DMPC_SET_LSTDOCS_SORTED_COLUMN: 'DMPC_SET_LSTDOCS_SORTED_COLUMN',
  DMPC_ES_LOGIN: 'DMPC_ES_LOGIN',
  DMPC_SET_ES_USER: 'DMPC_SET_ES_USER',
  DMPC_ES_LOGOUT: 'DMPC_ES_LOGOUT',
  DMPC_ES_LOGIN_FAILED: 'DMPC_ES_LOGIN_FAILED',
  DMPC_ES_LOGIN_SUCCESS: 'DMPC_ES_LOGIN_SUCCESS',
  DMPC_EMPTY_DOCUMENTS_CACHE: 'DMPC_EMPTY_DOCUMENTS_CACHE',
  DMPC_SET_HR: 'DMPC_SET_HR',
  DMPC_SET_USER_JWT: 'DMPC_SET_USER_JWT',
  SET_PIN_CODE: 'SET_PIN_CODE',
  EXPIRED_SESSION: 'EXPIRED_SESSION',
  REMOVED_CPX: 'REMOVED_CPX',
};

export const dmpconnectAPIConstants = {
  DMPC_ACCESSIBLE_DMP_LIST_TYPE_LAST_AUTHORIZATION: 'LASTAUTORIZATION',
  DMPC_ACCESSIBLE_DMP_LIST_TYPE_LAST_DOC: 'LASTDOC',
  DMPC_AUTHORIZATIONS_LIST_ALL: 1,
  DMPC_AUTHORIZATIONS_LIST_AUTHORIZED: 2,
  DMPC_AUTHORIZATIONS_LIST_BLOCKED: 3,
};

export const dmpconnectHashes = {
  REDIRECT_TO_LAST_VSM_OR: '#redirect-to-last-vsm-or-hr',
  SECRET_CONNEXION: '#secret',
};

export const dmpconnectSetTreatingPhysicianActions = [
  dmpconnectActionConstants.DMPC_CREATE_DMP_AND_OTP,
  dmpconnectActionConstants.DMPC_CREATE_DMP,
  dmpconnectActionConstants.DMPC_REACTIVATE_DMP,
];

export const dmpconnectGeneratePDFActions = [
  dmpconnectActionConstants.DMPC_REACTIVATE_DMP,
  dmpconnectActionConstants.DMPC_EDIT_DMP,
];

export const userAuthorizationStatuses = {
  AuthorizationError: 1,
  AuthorizationExist: 2,
  AuthorizationExpired: 3,
  AuthorizationDenied: 4,
  NoAuthorization: 5,
};

export const userAuthorizationStatusMessages = {
  [userAuthorizationStatuses.AuthorizationError]: 'Le DMP est clos ou n\'existe pas',
  [userAuthorizationStatuses.AuthorizationExist]: 'Vous disposez d\'une autorisation d\'accès à ce DMP',
  [userAuthorizationStatuses.AuthorizationExpired]: 'L\'autorisation d\'accès a expiré',
  [userAuthorizationStatuses.AuthorizationDenied]: 'Accès refusé',
  [userAuthorizationStatuses.NoAuthorization]: 'Vous ne disposez d\'aucune autorisation pour accéder à ce DMP',
};

export const dmpStatuses = {
  DMPExist: 1,
  DMPIsClosed: 2,
  DMPNotFound: 3,
  DMPError: 4,
};

/**
 * @type {{AddAuthorization: number, RemoveAuthorization: number}}
 */
export const userAuthorizationAction = {
  AddAuthorization: 1,
  RemoveAuthorization: 2,
};

/**
 * @type {{NormalAccess: number, BrisDeGlace: number, Centre15: number}}
 */
export const accessModes = {
  NormalAccess: 1,
  BrisDeGlace: 2,
  Centre15: 3,
};

export const documentFormats = {
  textPlain: 1,
  richTextFormat: 2,
  jpeg: 3,
  tiff: 4,
  pdf: 5,
  vsm: 6,
  biologicalReport: 7,
  dlu: 40,
  fludt: 41,
  fludr: 42,
  ldlEes: 44,
  ldlSes: 45,
  hr: 59,
  vaccin: 60,
  json: 999,
};

export const documentFormatsCda = [
  documentFormats.vsm,
  documentFormats.dlu,
  documentFormats.fludt,
  documentFormats.fludr,
  documentFormats.biologicalReport,
  documentFormats.ldlEes,
  documentFormats.ldlSes,
  documentFormats.hr,
  documentFormats.vaccin,
];

export const documentFormatsArray = Object.entries(documentFormats).map(entry => entry[1]);

export const documentFormatTypeCodes = {
  [documentFormats.vsm]: ['SYNTH'],
};

export const cardStatuses = {
  NotInitialized: 0,
  ValidCardInserted: 1,
  NoCardInserted: 2,
  UnknownCardInserted: 3,
  CardStatusChanged: 4,
  CardIsBlocked: 5,
  CardIsExpired: 6,
  CpxReaderError: 7,
};

export const readerSlotTypes = {
  NONE: 1,
  VITALE: 2,
  CPS: 3,
  UNKNOWN: 4,
  ERROR: 5,
};
export const readerSlotTypesLabel = {
  [readerSlotTypes.NONE]: 'Aucune carte',
  [readerSlotTypes.VITALE]: 'Carte Vitale',
  [readerSlotTypes.CPS]: 'Carte CPS',
  [readerSlotTypes.UNKNOWN]: 'Carte inconnue',
  [readerSlotTypes.ERROR]: 'Détection impossible',
};


// TODO refactoring put format description in one single object
export const documentFormatNames = {
  [documentFormats.textPlain]: 'Texte',
  [documentFormats.richTextFormat]: 'RTF',
  [documentFormats.jpeg]: 'JPEG',
  [documentFormats.tiff]: 'TIFF',
  [documentFormats.pdf]: 'PDF',
  [documentFormats.vsm]: 'VSM',
  [documentFormats.biologicalReport]: 'XD-LAB',
  [documentFormats.dlu]: 'DLU',
  [documentFormats.fludt]: 'FLUDT',
  [documentFormats.fludr]: 'FLUDR',
  [documentFormats.hr]: 'Historique Remboursement',
  [documentFormats.vaccin]: 'Historique Vaccination',
  [documentFormats.ldlEes]: 'LDL-EES',
  [documentFormats.ldlSes]: 'LDL-SES',
};

export const documentFormatTypeMimes = {
  [documentFormats.textPlain]: 'text/plain',
  [documentFormats.richTextFormat]: 'text/rtf',
  [documentFormats.jpeg]: 'image/jpeg',
  [documentFormats.tiff]: 'image/tiff',
  [documentFormats.pdf]: 'application/pdf',
  [documentFormats.vsm]: 'text/html',
  [documentFormats.biologicalReport]: 'text/html',
  [documentFormats.dlu]: 'text/html',
  [documentFormats.fludt]: 'text/html',
  [documentFormats.fludr]: 'text/html',
  [documentFormats.hr]: 'text/html',
  [documentFormats.vaccin]: 'text/html',
  [documentFormats.json]: 'application/json',
  [documentFormats.ldlEes]: 'text/html',
  [documentFormats.ldlSes]: 'text/html',
};

export const documentFormatExtensionsUpload = {
  [documentFormats.textPlain]: ['txt'],
  [documentFormats.richTextFormat]: ['rtf'],
  [documentFormats.jpeg]: ['jpeg', 'jpg'],
  [documentFormats.tiff]: ['tiff', 'tif'],
  [documentFormats.pdf]: ['pdf'],
  [documentFormats.json]: ['json'],
};
// [documentFormats.vsm]: ['html'],
// [documentFormats.biologicalReport]: ['html'],

export const documentFormatExtensions = {
  [documentFormats.textPlain]: 'txt',
  [documentFormats.richTextFormat]: 'rtf',
  [documentFormats.jpeg]: 'jpeg',
  [documentFormats.tiff]: 'tiff',
  [documentFormats.pdf]: 'pdf',
  [documentFormats.vsm]: 'html',
  [documentFormats.biologicalReport]: 'html',
  [documentFormats.dlu]: 'html',
  [documentFormats.fludt]: 'html',
  [documentFormats.fludr]: 'html',
  [documentFormats.ldlEes]: 'html',
  [documentFormats.ldlSes]: 'html',
  [documentFormats.hr]: 'html',
  [documentFormats.vaccin]: 'html',
  [documentFormats.json]: 'json',
};

export const documentFormatB64Signatures = {
  '/9j/4AAQS': documentFormats.jpeg,
  JVBERi0: documentFormats.pdf,
  e1xydGYxX: documentFormats.richTextFormat,
  PCFET0NUW: documentFormats.vsm,
};

export const documentFormatPreviews = {
  [documentFormats.textPlain]: true,
  [documentFormats.richTextFormat]: true,
  [documentFormats.jpeg]: true,
  [documentFormats.tiff]: true,
  [documentFormats.pdf]: true,
  [documentFormats.vsm]: true,
  [documentFormats.biologicalReport]: true,
  [documentFormats.dlu]: true,
  [documentFormats.fludt]: true,
  [documentFormats.hr]: true,
  [documentFormats.vaccin]: true,
  [documentFormats.json]: false,
};

export const dateRangePresets = [
  {
    code: 'today',
    label: 'Aujourd\'hui',
    from: moment().format('DD/MM/YYYY'),
    to: '',
    bar: false,
    picker: true,
  },
  {
    code: 'yesterday',
    label: 'Hier',
    from: moment().subtract(1, 'days').format('DD/MM/YYYY'),
    to: '',
    bar: false,
    picker: true,
  },
  {
    code: '1-week',
    label: '1 semaine',
    from: moment().subtract(7, 'days').format('DD/MM/YYYY'),
    to: '',
    bar: true,
    picker: true,
  },
  {
    code: '1-month',
    label: '1 mois',
    from: moment().subtract(30, 'days').format('DD/MM/YYYY'),
    to: '',
    bar: true,
    picker: true,
  },
  {
    code: '2-months',
    label: '2 mois',
    from: moment().subtract(30 * 2, 'days').format('DD/MM/YYYY'),
    to: '',
    bar: false,
    picker: true,
  },
  {
    code: '6-months',
    label: '6 mois',
    from: moment().subtract(30 * 6, 'days').format('DD/MM/YYYY'),
    to: '',
    bar: false,
    picker: true,
  },
  {
    code: '1-year',
    label: '1 an',
    from: moment().subtract(365, 'days').format('DD/MM/YYYY'),
    to: '',
    bar: true,
    picker: true,
  },
  {
    code: '3-years',
    label: '3 ans',
    from: moment().subtract(365 * 3, 'days').format('DD/MM/YYYY'),
    to: '',
    bar: true,
    picker: true,
  },
];


export const userAuthenticationModes = {
  InternetProtocol: { label: 'InternetProtocol', value: 1 },
  InternetProtocolPassword: { label: 'InternetProtocolPassword', value: 2 },
  Kerberos: { label: 'Kerberos', value: 3 },
  MobileOneFactorUnregistered: { label: 'MobileOneFactorUnregistered', value: 4 },
  MobileTwoFactorUnregistered: { label: 'MobileTwoFactorUnregistered', value: 5 },
  MobileOneFactorContract: { label: 'MobileOneFactorContract', value: 6 },
  MobileTwoFactorContract: { label: 'MobileTwoFactorContract', value: 7 },
  Password: { label: 'Password', value: 8 },
  PasswordProtectedTransport: { label: 'PasswordProtectedTransport', value: 9 },
  PreviousSession: { label: 'PreviousSession', value: 10 },
  PublicKeyX509: { label: 'PublicKeyX509', value: 11 },
  PublicKeyPGP: { label: 'PublicKeyPGP', value: 12 },
  PublicKeySPKI: { label: 'PublicKeySPKI', value: 13 },
  PublicKeyXMLDigitalSignature: { label: 'PublicKeyXMLDigitalSignature', value: 14 },
  Smartcard: { label: 'Smartcard', value: 15 },
  SmartcardPKI: { label: 'SmartcardPKI', value: 16 },
  SoftwarePKI: { label: 'SoftwarePKI', value: 17 },
  Telephony: { label: 'Telephony', value: 18 },
  TelephonyNomadic: { label: 'TelephonyNomadic', value: 19 },
  TelephonyPersonalized: { label: 'TelephonyPersonalized', value: 20 },
  TelephonyAuthenticated: { label: 'TelephonyAuthenticated', value: 21 },
  SecureRemotePassword: { label: 'SecureRemotePassword', value: 22 },
  SSL_TLSCertificateBasedClientAuthentication: { label: 'SSL_TLSCertificateBasedClientAuthentication', value: 23 },
  TimeSyncToken: { label: 'TimeSyncToken', value: 24 },
  Unspecified: { label: 'Unspecified', value: 25 },
};

export const userAuthenticationContexts = {
  AuthContext_CPS: { label: 'AuthContext_CPS', value: 1 },
  AuthContext_CARTE_ETS: { label: 'AuthContext_CARTE_ETS', value: 2 },
  AuthContext_CPSSANSCONTACT: { label: 'AuthContext_CPSSANSCONTACT', value: 3 },
  AuthContext_OTP_SMS: { label: 'AuthContext_OTP_SMS', value: 4 },
  AuthContext_APP_MOBILE_AUTH: { label: 'AuthContext_APP_MOBILE_AUTH', value: 5 },
  AuthContext_APP_BROWSER_AUTH: { label: 'AuthContext_APP_BROWSER_AUTH', value: 6 },
  AuthContext_Unspecified: { label: 'AuthContext_Unspecified', value: 100 },
};

export const availableAirStrongAuthModes = {
  CPS_PIN: { value: 1, label: 'Lecture CPS + Code PIN' },
  SMARTCARDPKI_PIN: { value: 2, label: 'Carte Etab sans contact + Code PIN' },
  PREVIOUSSESSION_CPSSANSCONTACT: { value: 3, label: 'Lecture CPS + Authentification sans contact', default: true },
  MOBILETWOFACTORCONTRACT_OTP: { value: 4, label: 'Login/Password + OTP' },
  MOBILETWOFACTORCONTRACT_MOBILE_AUTH: { value: 5, label: 'Login/Password + Code Pin mobile' },
  MOBILETWOFACTORCONTRACT_BROWSER_AUTH: { value: 6, label: 'Login/Password + Code Pin navigateur' },
  NONE: { value: 100, label: 'Aucune' },
};
export const searchPanels = {
  ACCESS_BY_VITALE_CARD_PANEL: 'vitale',
  ACCESS_BY_AVAILABLE_DMP_PANEL: 'patients',
  ACCESS_BY_SEARCH_PANEL: 'search',
  ACCESS_BY_INS_PANEL: 'ins-search',
  VIRTUAL_PRINTER_FILE_PANEL: 'VIRTUAL_PRINTER_FILE_PANEL',
  ACCESS_BY_INS_SEARCH_PANEL: 'insi-search',
};
export const directDmpStatusPerformer = {
  PS: 1,
  ES: 2,
};

export const dmpLandingPages = {
  PARCOURS_SOIN: 'PARCOURS_SOIN',
  DOCUMENTS: 'DOCUMENTS',
};

export const identifierTypes = {
  insPatient: 1,
  insCPatient: 2,
  rpps: 3,
  adeli: 4,
  sirius: 5,
  student: 6,
  structureAdeli: 7,
  structureFiness: 8,
  structureSiren: 9,
  structureSiret: 10,
  internalRpps: 11,
  internalOther: 12,
};
