import jwt from 'jsonwebtoken';
import { jwtErrors } from 'dmpconnectjsapp-base/errors';
import { createError } from '../errors';
import { errorTypes } from '../errors/errorConfiguration';
import { jsonWenTokenErrorsToJwtErrors } from '../errors/errorConstants';
import env from '../../envVariables';

export function extractHpInfosFromToken(decodedJwt, token) {
  const {
    hpGiven,
    hpInternalId,
    hpName,
    hpProfession,
    hpProfessionOid,
    hpSpeciality,
    hpAuthenticationMode,
    hpAuthenticationContext,
    serviceName,
  } = decodedJwt;


  if (!hpGiven || !hpInternalId || !hpName || !hpProfession || !hpProfessionOid || !hpSpeciality) {
    return {
      valid: false,
      error: createError(errorTypes.JWTErrors, jwtErrors.MISSING_CLAIMS),
    };
  }

  return {
    valid: true,
    values: {
      hpGiven,
      hpInternalId,
      hpName,
      hpProfession,
      hpProfessionOid,
      hpSpeciality,
      hpAuthenticationMode,
      hpAuthenticationContext,
      serviceName,
      token,
    },
  };
}

export function verifyAndDecodeJWT(token, getKey = null) {
  try {
    const decodedJwt = jwt.verify(
      token,
      getKey || env.REACT_APP_ES_JWT_KEY || '3s6v8y/B?E(H+MbQeThWmZq4t7w!z$C&',
    );
    return extractHpInfosFromToken(decodedJwt, token);
  } catch (e) {
    return {
      valid: false,
      error: createError(errorTypes.JWTErrors, jsonWenTokenErrorsToJwtErrors[e.name] || jwtErrors.INVALID_TOKEN),
    };
  }
}
