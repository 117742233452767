import {
  delay, put, select, takeEvery, take, call, all,
} from 'redux-saga/effects';
import { push } from 'connected-react-router';
import {
  clearSubSection,
  dmpCommandFailureContextualizedType,
  dmpCommandSuccessContextualizedType,
} from 'dmpconnectjsapp-base/actions';
import { API_TYPES, apiSections } from 'dmpconnectjsapp-base/constants';
import {
  formatGetAccessibleDMPListParams,
  formatGetInsFromIdentityInformationParams,
  formatGetINSFromVitaleCardParams, formatValidateFromINSiIdentity,
} from 'dmpconnectjsapp-base/actions/config/commandParamsFormatters';
import commands from 'dmpconnectjsapp-base/actions/config/commands';
import { getApiType, getConfigurationValue, getInsiConfig } from 'dmpconnectjsapp-base/helpers/accessors';
import { getAccessRightsProps, isTransactionAllowed, transactions } from 'dmpconnectjsapp-base/rules/accessRights';
import { b64DecodeUnicode } from 'dmpconnectjsapp-base/utils/dataUtils';
import { xml2js } from 'xml-js';
import { hasError, isReady } from 'dmpconnectjsapp-base/helpers/common';
import moment from 'moment';
import {
  associateInsiIdentity,
  emptyDocumentCache, getAction,
  getDirectAuthenticationDMPStatus, selectINS, setCurrentFilter, setFindPatientsIns,
} from '../actions';
import {
  dmpconnectAPIConstants,
  dmpconnectApplicationActionConstants,
  dmpStatuses,
  userAuthorizationAction, userAuthorizationStatuses,
} from '../constants';
import { getCurrentFilter } from '../helpers/searchUtils';
import { getAccessRights } from './utilsSagas';
import { getDmpLandingPage, getDocumentRedirectUrl } from '../helpers';
import { getVitaleCardDataFromIndex } from '../helpers/vitaleCard';
import patients from '../mock/insiWS2';
import { parseXmlToJsResult } from '../helpers/remote';
import { getCertifiedIdentityFromIns, getQualifiedInsINSi } from '../helpers/certifiedIdentity';
import { INSiIdentitySources } from '../constants/dmpConstants';
import { handleExportVitaleCardData } from './remoteSagas';
import { getReaderName } from '../helpers/readers';
import getIsMobileFromState from '../helpers/isMobile';
import { getMobileLandingUrl } from '../helpers/nav';


// /**
//  * @param data
//  * @returns {IterableIterator<PutEffect<Function>>}
//  */
// const getDirectAuthenticationAfterCertifiedIdentitySuccess = function* ({ data }) {
//   const ins = data.Ins.s_ins + data.Ins.s_insType;
//   yield put(getDirectAuthenticationDMPStatus(ins, null, false));
// };
// const getDirectAuthenticationAfterINSiSuccess = function* ({ data, context }) {
//   const { dmpStatusCall = true } = context;
//   if (dmpStatusCall === true) {
//     const { i_insIdentityResult } = data;
//     if (i_insIdentityResult === 2) {
//       const ins = getQualifiedInsINSi(data);
//       yield put(getDirectAuthenticationDMPStatus(ins, null, true));
//     }
//   }
// };

const handleAuthorizationUpdateWithSecretConnection = function* ({ actions, ins, secretConnection }) {
  yield put(getAction(
    commands.setConfidentialityLevel,
    apiSections.CONFIDENTIALITY_LEVEL_SECTION,
    { i_enableSecretConnection: secretConnection ? 1 : 0 },
    { subSection: ins },
  ));
  const confidentialityLevelResult = yield take([
    dmpCommandSuccessContextualizedType(apiSections.CONFIDENTIALITY_LEVEL_SECTION),
    dmpCommandFailureContextualizedType(apiSections.CONFIDENTIALITY_LEVEL_SECTION),
    dmpCommandSuccessContextualizedType(apiSections.CONFIDENTIALITY_LEVEL_SECTION_REST),
  ]);

  if (
    confidentialityLevelResult
    && (
      confidentialityLevelResult.type === dmpCommandSuccessContextualizedType(apiSections.CONFIDENTIALITY_LEVEL_SECTION)
      || confidentialityLevelResult.type === dmpCommandSuccessContextualizedType(apiSections.CONFIDENTIALITY_LEVEL_SECTION_REST)
    )
  ) {
    yield all(actions.map(action => put(action)));
  }
};

const getDirectAuthenticationAfterAccessAuthorizationUpdate = function* ({
  context: {
    params: {
      ins,
      performer,
      fromSetTreatingPhysician,
      fromManageDMPProcess,
    },
  },
  command,
}) {
  yield put(clearSubSection(apiSections.FIND_DOCUMENTS_SECTION, ins));
  yield put(emptyDocumentCache(ins));
  if (!fromManageDMPProcess) {
    const { accessRights } = yield select(getAccessRightsProps);
    // update td0.4 : authorisationDate >= j-1
    // appel normal
    if (isTransactionAllowed(accessRights, transactions.AUTHORIZED_DMPS)) {
      yield put(
        getAction(
          commands.getAccessibleDMPList,
          apiSections.ACCESSIBLE_DMP_LIST_SECTION,
          formatGetAccessibleDMPListParams({
            type: dmpconnectAPIConstants.DMPC_ACCESSIBLE_DMP_LIST_TYPE_LAST_AUTHORIZATION,
            date: moment().subtract(1, 'day').format('YYYYMMDD'),
          }),
          {
            subSection: performer,
            silentError: true,
          },
        ),
      );
    }

    yield put(getDirectAuthenticationDMPStatus(ins, performer));
    // after we received the TD0.2 result, check if we have a result for INSi from vitale card and update the cache with it
    yield take([
      dmpCommandSuccessContextualizedType(apiSections.DIRECT_AUTHENTICATION_DMP_STATUS_SECTION),
      dmpCommandFailureContextualizedType(apiSections.DIRECT_AUTHENTICATION_DMP_STATUS_SECTION),
    ]);
    const identity = yield select(getCertifiedIdentityFromIns, ins);
    if (identity) {
      const { params: { source } = {} } = identity;
      if (source === INSiIdentitySources.VITALE) {
        yield put(associateInsiIdentity(ins, identity, performer));
      }
    }
  }

  if (!fromSetTreatingPhysician) {
    const { i_action } = command;
    const isMobile = yield select(getIsMobileFromState);

    if (i_action === userAuthorizationAction.AddAuthorization) {
      const { accessRights } = yield select(getAccessRights);
      const dmpLandingPage = yield select(getDmpLandingPage);
      const url = isMobile
        ? getMobileLandingUrl(ins)
        : getDocumentRedirectUrl(accessRights, ins, dmpLandingPage);
      yield put(selectINS(ins));
      yield put(push(url));
    } else {
      yield put(push('/home'));
    }
  }
};
const getDirectAuthenticationAfterCloseDMP = function* ({ context: { subSection: ins } }) {
  yield delay(2000);
  yield put(getDirectAuthenticationDMPStatus(ins));
};
const getDirectAuthenticationAfterConvertInsCToNir = function* ({ data }) {
  const ins = data.Ins.s_ins + data.Ins.s_insType;
  yield put(getDirectAuthenticationDMPStatus(ins));
  // yield put(selectINS(ins));
  yield put(setFindPatientsIns(ins));
};
const deselectINS = function* () {
  yield put(selectINS(null));
};

const emptyCacheAfterDirectAuth = function* (action) {
  const {
    data: {
      ExistingTestAnswer: {
        i_dmpStatus: dmpStatus,
        i_userAuthorization: authorization,
      } = {},
    } = {},
    context: { params: { ins } },
  } = action;

  if (authorization !== userAuthorizationStatuses.AuthorizationExist || dmpStatus !== dmpStatuses.DMPExist) {
    yield put(clearSubSection(apiSections.FIND_DOCUMENTS_SECTION, ins));
    yield put(emptyDocumentCache(ins));
  }
};

const getVitaleDataSection = ({ dmpconnect: { [apiSections.VITALE_DATA_SECTION]: section } }) => section;

export const getVitaleCardXmlContent = function* (action) {
  const vitaleReaderName = yield select(getReaderName, 'vitaleCardReader');
  if (vitaleReaderName.indexOf('WIRELESS') === -1) {
    yield put(getAction(
      commands.getSessionState,
      apiSections.SESSION_STATE_SECTION,
    ));
    const sessionStateResult = yield take([
      dmpCommandSuccessContextualizedType(apiSections.SESSION_STATE_SECTION),
      dmpCommandFailureContextualizedType(apiSections.SESSION_STATE_SECTION),
    ]);

    if (sessionStateResult && sessionStateResult.type === dmpCommandSuccessContextualizedType(apiSections.SESSION_STATE_SECTION)) {
      // get vitale xml
      const { i_vitaleCard: vitaleHandle = 0 } = sessionStateResult.data;
      if (vitaleHandle > 0) {
        yield put(getAction(
          commands.getVitaleXmlContent,
          apiSections.VITALE_XML_CONTENT_SECTION,
          vitaleHandle,
        ));

        const result = yield take([
          dmpCommandSuccessContextualizedType(apiSections.VITALE_XML_CONTENT_SECTION),
          dmpCommandFailureContextualizedType(apiSections.VITALE_XML_CONTENT_SECTION),
        ]);

        if (result && result.type === dmpCommandSuccessContextualizedType(apiSections.VITALE_XML_CONTENT_SECTION)) {
          const { data: { s_utf8String } } = result;
          const xmlContent = b64DecodeUnicode(s_utf8String);
          const xml = xml2js(xmlContent, { compact: true });

          const xmlToJs = parseXmlToJsResult(xml);

          // call remote export vitale data
          yield call(handleExportVitaleCardData, action, xmlToJs);

          const {
            T_AsnDonneesVitale: {
              tech: {
                numSerie: {
                  _text: numSerie = '',
                } = {},
              } = {},
              listeBenef: {
                T_AsnBeneficiaire: vitalePatients = [],
              } = {},
            } = {},
          } = xml;

          let patientsArray = vitalePatients;
          if (!Array.isArray(vitalePatients)) {
            patientsArray = [vitalePatients];
          }

          let RightHolderVitaleData = {};

          const patientsData = patientsArray.map((patient) => {
            const {
              amo: {
                qualBenef: {
                  _text: qualBenef = '',
                } = {},
                centreCarte: {
                  _text: centreCarte = '',
                } = {},
                codeGestion: {
                  _text: codeGestion = '',
                } = {},
                codeRegime: {
                  _text: codeRegime = '',
                } = {},
              } = {},
              ident: {
                dateCertification: {
                  _text: dateCertification = '',
                } = {},
                naissance: {
                  date: {
                    _text: date = '',
                  } = {},
                  dateEnCarte: {
                    _text: dateEnCarte = '',
                  } = {},
                } = {},
                nir: {
                  _text: nir = '',
                } = {},
                nirCertifie: {
                  _text: nirCertifie = '',
                } = {},
                nomPatronymique: {
                  _text: nomPatronymique = '',
                } = {},
                nomUsuel: {
                  _text: nomUsuel = '',
                } = {},
                prenomUsuel: {
                  _text: prenomUsuel = '',
                } = {},
                rangDeNaissance: {
                  _text: rangDeNaissance = '',
                } = {},

              } = {},
            } = patient;

            const splittedNir = nir.split(' ');
            const splittedNirCertifie = nirCertifie.split(' ');

            if (Number(qualBenef) === 0) {
              RightHolderVitaleData = {
                s_birthName: nomPatronymique,
                NirData: {
                  Nir: {
                    s_nir: splittedNir[0],
                    s_key: splittedNir[1],
                  },
                },
              };
            }

            return {
              BeneficiaryVitaleData: {
                s_name: nomUsuel,
                s_birthName: nomPatronymique,
                s_givenName: prenomUsuel,
                s_birthday: date || dateEnCarte,
                i_rank: Number(rangDeNaissance),
                i_qualBenef: Number(qualBenef),
                NirData: {
                  Nir: {
                    s_nir: splittedNir[0],
                    s_key: splittedNir[1],
                  },
                  CertifiedNir: {
                    s_nir: splittedNirCertifie && splittedNirCertifie.length === 2 ? splittedNirCertifie[0] : '',
                    s_key: splittedNirCertifie && splittedNirCertifie.length === 2 ? splittedNirCertifie[1] : '',
                  },
                  s_certificationDate: dateCertification,
                },
                HealthCoverage: {
                  s_regime: codeRegime,
                  s_desk: codeGestion,
                  s_center: centreCarte,
                },
              },
              s_serialNumber: numSerie,
            };
          });


          yield put({
            ...result,
            type: 'DMPC_COMMAND_SUCCESS',
            data: {
              ...result.data,
              xmlPatients: patientsData,
              RightHolderVitaleData,
              xmlToJs,
            },
          });
        }
      }
    }
  }

  return null;
};


const getDmpConnectPersistedAppConfiguration = ({ dmpConnectPersistedAppConfiguration }) => dmpConnectPersistedAppConfiguration;

const getIns = function* (action) {
  const apiType = yield select(getApiType);
  const vitaleData = yield select(getVitaleDataSection);
  const tlsiUrl = yield select(({ dmpconnectConnectorConfig: { tlsiServerName } }) => tlsiServerName);

  const insiConfig = yield select(getInsiConfig);
  const dmpConnectPersistedAppConfiguration = yield select(getDmpConnectPersistedAppConfiguration);
  const insiVitaleWS2 = getConfigurationValue('insiVitaleWS2', dmpConnectPersistedAppConfiguration);
  // const billingNumber = getUserConfiguration({ s_internalId: accessRights.psId }, 'billingNumber', dmpConnectCPxConfiguration);

  const {
    ins2Patient,
    vitaleIndex,
    insFromVitaleCard,
    dmpStatusCall,
    subSection,
    vitaleCardStatus,
    vitaleXml,
  } = action;
  let patient = ins2Patient;
  // forcer la recherche par traits si insiVitaleWS2
  if (vitaleIndex >= 0 && insiVitaleWS2 && !patient) {
    const vitalePatient = getVitaleCardDataFromIndex(vitaleData, vitaleIndex);
    patient = patients.find(p => p.name === vitalePatient.s_name && p.given === vitalePatient.s_given);
    if (patient) patient.subSection = subSection || `${insFromVitaleCard}_${vitaleIndex}`;
  }

  if (patient) { // par traits
    yield put(getAction(
      commands.getInsFromIdentityInformation,
      apiSections.INS_FROM_VITALE_CARD,
      {
        s_insiUrl: tlsiUrl,
        ...formatGetInsFromIdentityInformationParams({
          name: patient.name,
          given: patient.given,
          birthday: patient.birthday,
          sex: patient.sex,
          idam: insiConfig.idam,
          numAm: insiConfig.numAm,
          lpsName: insiConfig.lpsName,
          lpsVersion: insiConfig.lpsVersion,
          billingNumber: insiConfig.billingNumber,
        }),
      },
      {
        subSection: subSection || patient.subSection || `${insFromVitaleCard}_${vitaleIndex}`,
        contextParams: {
          vitaleIndex,
          insFromVitaleCard,
          vitaleCardStatus,
          source: INSiIdentitySources.IDENTITY,
        },
        contextExtra: { dmpStatusCall },
        silentError: true,
      },
    ));
  } else if (vitaleIndex >= 0) { // par carte vitale
    if (apiType === API_TYPES.REST) {
      yield put(getAction(
        commands.getInsFromRawVitaleData,
        apiSections.INS_FROM_VITALE_CARD,
        {
          LpsInfos: {
            s_idam: insiConfig.idam,
            s_numAM: insiConfig.numAm,
            s_version: insiConfig.lpsVersion,
            s_instance: '550e8400-e29b-41d4-a716-446655440000',
            s_name: insiConfig.lpsName,
            s_billingNumber: insiConfig.billingNumber,
          },
          s_insiUrl: tlsiUrl,
          ...vitaleXml.xmlPatients[vitaleIndex],
          RightHolderVitaleData: vitaleXml.RightHolderVitaleData,
        },
        {
          subSection: subSection || `${insFromVitaleCard}_${vitaleIndex}`,
          contextParams: {
            vitaleIndex,
            insFromVitaleCard,
            vitaleCardStatus,
            source: INSiIdentitySources.VITALE,
          },
          contextExtra: { dmpStatusCall },
          silentError: true,
        },
      ));
    } else {
      yield put(getAction(
        commands.getInsFromVitaleCard,
        apiSections.INS_FROM_VITALE_CARD,
        formatGetINSFromVitaleCardParams({
          vitaleIndex,
          idam: insiConfig.idam,
          numAm: insiConfig.numAm,
          lpsName: insiConfig.lpsName,
          lpsVersion: insiConfig.lpsVersion,
          billingNumber: insiConfig.billingNumber,
        }),
        {
          subSection: subSection || `${insFromVitaleCard}_${vitaleIndex}`,
          contextParams: {
            vitaleIndex,
            insFromVitaleCard,
            vitaleCardStatus,
            source: INSiIdentitySources.VITALE,
          },
          contextExtra: { dmpStatusCall },
          silentError: true,
        },
      ));
    }
  }
};

const checkAndGetIns = function* (action) {
  const { identity, subSection } = action;
  const insiConfig = yield select(getInsiConfig);
  const tlsiUrl = yield select(({ dmpconnectConnectorConfig: { tlsiServerName } }) => tlsiServerName);
  let result;
  const formattedParams = formatValidateFromINSiIdentity({
    identity,
    idam: insiConfig.idam,
    numAm: insiConfig.numAm,
    lpsName: insiConfig.lpsName,
    lpsVersion: insiConfig.lpsVersion,
    billingNumber: insiConfig.billingNumber,
  });

  if (isReady(identity) && identity.i_insIdentityResult === 2) {
    yield put(getAction(
      commands.checkIdentity,
      apiSections.INSI_CHECK_IDENTITY,
      {
        s_insiUrl: tlsiUrl,
        ...formattedParams,
      },
      {
        contextParams: { identity, subSection },
        subSection,
        silentError: true,
      },
    ));

    while (!result) {
      const insValidation = yield take([
        dmpCommandSuccessContextualizedType(apiSections.INSI_CHECK_IDENTITY),
        dmpCommandFailureContextualizedType(apiSections.INSI_CHECK_IDENTITY),
      ]);

      const {
        context: {
          params: {
            subSection: resultSubSection,
          },
        },
      } = insValidation;

      if (resultSubSection === subSection) {
        result = insValidation;
      }
    }
  } else {
    result = { s_status: 'KO' };
  }

  // if the identity is not valid, we run a search
  if (hasError(result)) {
    yield call(getIns, {
      ins2Patient: {
        name: formattedParams.s_birthName,
        given: formattedParams.s_given,
        birthday: formattedParams.s_birthDate,
        sex: formattedParams.i_sex,
        birthplace: formattedParams.s_birthPlace,
      },
      subSection,
    });
  }
};

const associateIdentityToPatientAfterINSiSuccess = function* ({ data, context }) {
  const { subSection } = context;
  const { accessRights: { psId } } = yield select(getAccessRightsProps);

  if (isReady(data)) {
    let ins;
    if (data.i_insIdentityResult === 2) {
      ins = getQualifiedInsINSi(data);
    } else ins = subSection;
    yield put(associateInsiIdentity(ins, data, psId));
  } else {
    yield put(associateInsiIdentity(subSection, undefined, psId));
  }
};

function* saveAccessibleDmpsIds(action) {
  const { data: { AccessibleDmps = [] }, context: { params: { saveResultIds = false } = {} } = {} } = action;

  if (saveResultIds === true) {
    yield put(setCurrentFilter('availableDmpIds', AccessibleDmps.map(dmp => dmp.s_ins)));
  } else {
    const currentDmps = yield select(getCurrentFilter, 'availableDmpIds');
    yield put(setCurrentFilter('availableDmpIds', [...(currentDmps || []), ...AccessibleDmps.map(dmp => dmp.s_ins)]));
  }
}

export const handlePatientCallbacks = function* () {
  // no need anymore now that we use a HOC to provide direct auth status
  // yield takeEvery(
  //   dmpCommandSuccessContextualizedType(apiSections.CERTIFIFIED_IDENTITY_SECTION),
  //   getDirectAuthenticationAfterCertifiedIdentitySuccess,
  // );
  // yield takeEvery(
  //   dmpCommandSuccessContextualizedType(apiSections.INS_FROM_VITALE_CARD),
  //   getDirectAuthenticationAfterINSiSuccess,
  // );
  yield takeEvery(
    dmpCommandSuccessContextualizedType(apiSections.ACCESSIBLE_DMP_LIST_SECTION),
    saveAccessibleDmpsIds,
  );
  yield takeEvery(
    dmpconnectApplicationActionConstants.UPDATE_USER_DMP_ACCESS_AUTHORIZATION,
    handleAuthorizationUpdateWithSecretConnection,
  );
  yield takeEvery(
    dmpCommandSuccessContextualizedType(apiSections.USER_DMP_ACCESS_AUTHORIZATION_SECTION),
    getDirectAuthenticationAfterAccessAuthorizationUpdate,
  );
  yield takeEvery(dmpCommandSuccessContextualizedType(apiSections.INSC_TO_NIR),
    getDirectAuthenticationAfterConvertInsCToNir);
  yield takeEvery(
    dmpCommandSuccessContextualizedType(apiSections.CLOSE_DMP_SECTION),
    getDirectAuthenticationAfterCloseDMP,
  );
  yield takeEvery(dmpCommandFailureContextualizedType(apiSections.DIRECT_AUTHENTICATION_DMP_STATUS_SECTION), deselectINS);
  yield takeEvery(dmpCommandSuccessContextualizedType(apiSections.DIRECT_AUTHENTICATION_DMP_STATUS_SECTION), emptyCacheAfterDirectAuth);

  yield takeEvery(dmpconnectApplicationActionConstants.DMPC_GET_INS_FROM_VITALE_CARD, getIns);
  yield takeEvery(dmpconnectApplicationActionConstants.DMPC_CHECK_AND_GET_INS, checkAndGetIns);
  yield takeEvery([
    dmpCommandSuccessContextualizedType(apiSections.INS_FROM_VITALE_CARD),
    dmpCommandFailureContextualizedType(apiSections.INS_FROM_VITALE_CARD),
  ], associateIdentityToPatientAfterINSiSuccess);
};
