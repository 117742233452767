import { push } from 'connected-react-router';
import {
  call, put, select, take, takeEvery,
} from 'redux-saga/effects';
import { getApiType, getConfigurationValue } from 'dmpconnectjsapp-base/helpers/accessors';
import {
  apiSections,
  dmpconnectActionConstants,
  dmpconnectInitActionConstants,
  mssActionConstants,
} from 'dmpconnectjsapp-base/constants';
import { handleCancelCommand } from 'dmpconnectjsapp-base/sagas/connectorSagas';
import {
  clearSection,
  clearSubSection,
  dmpCommandFailureContextualizedType,
  dmpCommandSuccessContextualizedType, dmpRemoteCommandSuccessContextualizedType,
  resetALDiConfiguration,
  resetApCvConfiguration,
  resetDpConfiguration,
  resetESConfiguration,
  resetGlobalConfiguration,
  resetINSiConfiguration,
  resetMssConfiguration,
  setPersistedConnectorConfiguration,
} from 'dmpconnectjsapp-base/actions';
import commands from 'dmpconnectjsapp-base/actions/config/commands';
import {
  formatGetCardParams,
  formatGetDocumentContentByUniqueIdParams,
  formatObjectParameterIntParams,
} from 'dmpconnectjsapp-base/actions/config/commandParamsFormatters';
import { explodeUniqueUUid } from 'dmpconnectjsapp-base/helpers/findDocuments';

import { authenticationTypes, getAccessRightsProps } from 'dmpconnectjsapp-base/rules/accessRights';
import {
  getAction, setWebPsRootUrls,
  esLoginSuccess,
  getRestPcscReaders,
  readVitaleProcess,
  resetOpenIDConfigFromEnv,
  stopCpxMonitoring, resetPersistedAppConfigurationFromEnv,
} from '../actions';
import {
  API_TYPES,
  dmpconnectApplicationActionConstants,
  dmpconnectConfigurationActionConstants,
  dmpconnectRemoteActionConstants,
  dmpconnectUserActionConstants,
  mssActions,
  readerSlotTypes,
} from '../constants';

import {
  esLoginProcess,
  handleReadCpxCard,
  initStatesAndMonitoring,
  initVirtualPrinter,
  loginProcess,
  logoutProcess,
  remoteMonitoringStartProcess,
  remoteMonitoringStopProcess,
} from './securitySagas';

import {
  clearPersistantDataFromState,
  getPersistantDataFromState,
  handleDeletePersistedDoc,
  handleSentDocument,
  refreshPersistantData,
  setPersistantDataToState,
} from './sendDocumentSaga';
import { dashboardInit } from './dashboardSaga';
import { checkRemoteControlStatus } from '../actions/dmpconnectRemoteActions';
import { getAccessRights, hardResetApplication, redirectAfterLogin } from './utilsSagas';
import {
  resetPDFs, setDMPAckPDF, setDMPCreatePDF, setDMPOtpPDF,
} from '../actions/dmpconnectPDFsActions';
import {
  getDmpLandingPage, getDocumentCdaContent, getDocumentRedirectUrl, getEsUser,
} from '../helpers';
import {
  checkMssLoginType,
  getPeopleInfosAfterReceiveMessages, handleAppendMessageToSentDirectory,
  handleDeleteMssFolder,
  handleDirectoryManagementResult,
  handleDownloadAndZipAllAttachments,
  handleDownloadAttachmentFromMssResult,
  handleFullSearchMessagesFromMssResult,
  handleGetFoldersFromMssResult,
  handleGetMessageContentResult,
  handleImapSyncMessages,
  handleRefreshFoldersList, handleRefreshSentFolder,
  handleSearchMessagesFromMssResult,
  handleSendMssWebEmail,
  handleSyncMessagesFromMssResult,
  handleUpdateMessagesFromMssResult, setMssAuthenticationId, setMssPscAccessToken,
} from './mssSagas';
import { manageDmpconnectErrors } from './connectorSagas';
import { forceApCvActivationAfterStartDate } from './apCvSagas';
import {
  aldConfigFromEnv,
  apCvConfigFromEnv,
  connectorConfigFromEnv,
  dpConfigFromEnv,
  esConfigFromEnv,
  insiConfigFromEnv,
  mssConfigFromEnv,
} from '../../reducers/configFromEnv';
import env from '../../envVariables';
import {
  handleExportCpxData,
  handleExportDocument,
  handleExportEmail,
  handleExportVitaleData,
} from './remoteSagas';
import { postMessageToIframeParent } from '../utils/iframe';
import {
  checkAndUpdatePreviousVersions,
  handleGetAndParseLastHR,
  refreshDocuments,
  refreshDocumentsAfterDelete,
  refreshDocumentsAfterDocumentUpdate,
  refreshDocumentsAfterParamsChanged,
} from './documentSagas';
import { getVitaleCardXmlContent } from './patientSagas';
import { getReaderName } from '../helpers/readers';
import getIsMobileFromState from '../helpers/isMobile';
import { getMobileLandingUrl } from '../helpers/nav';
import { objectWithoutTheseKeys } from '../utils/dataUtils';
import { extractPracticeLocationFromState } from '../helpers/practiceLocation';

/**
 *
 * @param ins
 * @param command
 * @returns {IterableIterator<PutEffect<CallHistoryMethodAction<[, ]>>>}
 */
const handleRedirectAfterAccessModeSuccess = function* ({ context: { params: { ins } } }) {
  if (ins) {
    const isMobile = yield select(getIsMobileFromState);
    const { accessRights } = yield select(getAccessRights);
    const dmpLandingPage = yield select(getDmpLandingPage);
    const url = isMobile
      ? getMobileLandingUrl(ins)
      : getDocumentRedirectUrl(accessRights, ins, dmpLandingPage);
    yield put(push(url));
    // yield put(push(`/dmp/${encodeIns(ins)}/documents${dmpconnectHashes.REDIRECT_TO_LAST_VSM_OR}`));
  }
};

// const getObjectParametersAfterGettingDocumentContent = function* ({ context: { subSection } }) {
//   yield put(getObjectParameters(subSection));
// };

//
// const refreshAccessibleDMPListAfterDMPStatusUpdate = function* () {
//   const { accessRights } = yield select(getAccessRights);
//
//   if (!isTransactionAllowed(accessRights, transactions.AUTHORIZED_DMPS)) return;
//
//   const params = yield select(getAccessibleDMPListParams);
//
//   if (params) {
//     const { type, date } = params;
//     yield put(clearSection(apiSections.ACCESSIBLE_DMP_LIST_SECTION));
//     yield put(getAccessibleDMPList(type, date));
//   }
// };

const refreshAdministrativeDataAfterUpdate = function* ({ context: { subSection: ins } }) {
  yield put(clearSubSection(apiSections.OTP_EMAIL_VALUE, ins));
  yield put(clearSubSection(apiSections.OTP_MOBILE_VALUE, ins));
  yield put(clearSubSection(apiSections.ADMINISTRATIVE_DATA_SECTION, ins));
  yield put(getAction(
    commands.getDmpAdministrativeData,
    apiSections.ADMINISTRATIVE_DATA_SECTION,
    { s_ins: ins },
    { subSection: ins },
  ));
};

const setRootUrlAndrequestWebPsUrls = function* ({ context: { subSection: ins } }) {
  yield put(setWebPsRootUrls());
  const { type } = yield take([
    dmpCommandSuccessContextualizedType(apiSections.WEB_PS_URLS),
    dmpCommandFailureContextualizedType(apiSections.WEB_PS_URLS),
  ]);
  if (type && type === dmpCommandSuccessContextualizedType(apiSections.WEB_PS_URLS)) {
    yield put(getAction(
      commands.getWebPsDmpUrls,
      apiSections.WEB_PS_URLS,
      { s_ins: ins },
      {
        subSection: ins,
        synchronous: true,
      },
    ));
  }
};

const getCpxType = function* ({ data, context: { noCardTypeCheck = true } }) {
  if (!noCardTypeCheck) {
    const cpxCard = yield select(state => state.dmpconnect[apiSections.CPX_CARD_SECTION]);
    if (!cpxCard.i_cpxCardType) {
      const { i_cpxCard: cpxCardHandle = 0 } = data;
      if (cpxCardHandle > 0) {
        yield put(getAction(
          commands.getObjectParameterInt,
          apiSections.CPX_CARD_TYPE_SECTION,
          formatObjectParameterIntParams(69, cpxCardHandle),
        ));
      }
    }
  }
};

const handleRequestPcscReaders = function* ({ synchronous }) {
  const apiType = yield select(getApiType);

  if (apiType !== API_TYPES.REST) {
    yield put(getAction(
      commands.getPcscReaders,
      apiSections.PCSC_READERS_SECTION,
      null,
      { synchronous: true, silentError: true },
    ));
  } else {
    yield put(getRestPcscReaders());
  }
};

function* getCpxHpInfos(index) {
  yield put(getAction(
    commands.getCpxHpInfos,
    apiSections.CPX_HP_INFOS_SECTION,
    null,
    {
      subSection: `reader_index_${index}`,
      contextParams: { index },
      silentError: true,
    },
  ));
  let result;
  while (!result) {
    const cardInfosResult = yield take(
      [
        dmpCommandSuccessContextualizedType(apiSections.CPX_HP_INFOS_SECTION),
        dmpCommandFailureContextualizedType(apiSections.CPX_HP_INFOS_SECTION),
      ],
    );

    const { context: { subSection } } = cardInfosResult;
    if (subSection === `reader_index_${index}`) {
      result = cardInfosResult;
    }
  }

  return result;
}

function* getCpxStatus(index) {
  yield put(getAction(
    commands.getCpxStatus,
    apiSections.CPX_STATUS_SECTION,
    null,
    {
      subSection: `reader_index_${index}`,
      contextParams: { index },
      silentError: true,
    },
  ));
  let result;
  while (!result) {
    const cardStatusResult = yield take(
      [
        dmpCommandSuccessContextualizedType(apiSections.CPX_STATUS_SECTION),
        dmpCommandFailureContextualizedType(apiSections.CPX_STATUS_SECTION),
      ],
    );

    const { context: { subSection } } = cardStatusResult;
    if (subSection === `reader_index_${index}`) {
      result = cardStatusResult;
    }
  }

  return result;
}

const getCpxHpInfo = function* (reader, index) {
  // CPX card object creation
  yield put(stopCpxMonitoring());
  yield put(getAction(
    commands.getCpxCard,
    apiSections.CPX_SECTION,
    formatGetCardParams(index),
    { synchronous: true, silentError: true },
  ));

  const getCpxCardResult = yield take(
    [
      dmpCommandSuccessContextualizedType(apiSections.CPX_SECTION),
      dmpCommandFailureContextualizedType(apiSections.CPX_SECTION),
    ],
  );

  let result;
  if (getCpxCardResult.type === dmpCommandSuccessContextualizedType(apiSections.CPX_SECTION)) {
    yield call(getCpxStatus, index);
    result = yield call(getCpxHpInfos, index);
  }

  return result;
};

const handlePcsReadersSuccess = function* (action) {
  const { data: { Readers = [] } } = action;
  const apiType = yield select(getApiType);
  if (apiType !== API_TYPES.REST) {
    yield put(clearSection(apiSections.CPX_HP_INFOS_SECTION));
    const exportActivated = Number(env.REACT_APP_REMOTE_CONTROL_AUTO_EXPORT_CPX_DATA) === 1;
    const cardsInfos = [];

    for (let index = 0; index < Readers.length; index += 1) {
      const reader = Readers[index];
      if (reader.i_slotType === readerSlotTypes.CPS) {
        cardsInfos.push(yield call(getCpxHpInfo, reader, index));
      }
    }

    if (exportActivated) {
      yield call(handleExportCpxData, cardsInfos.reduce((cards, card) => (
        card
          ? [...cards, objectWithoutTheseKeys(card.data, ['s_status'])]
          : cards
      ), []));
    }
  }
};

const handleCheckRemoteControlStatus = function* ({ context: { checkStatus } }) {
  if (checkStatus === true) {
    yield put(clearSection(apiSections.REMOTE_CONTROL_STATUS));
    yield put(checkRemoteControlStatus());
  }
};

const handleLoginProcess = function* (action) {
  const apiType = yield select(getApiType);

  if (apiType === API_TYPES.REST) {
    yield put(esLoginSuccess());
    yield call(redirectAfterLogin);
  } else {
    yield call(loginProcess, action);
  }
};

const handleEsLoginProcess = function* (action) {
  const apiType = yield select(getApiType);
  if (apiType === API_TYPES.REST) {
    yield put(esLoginSuccess());
    yield call(redirectAfterLogin);
  } else {
    yield call(esLoginProcess, action);
  }
};

const setPatientWEBPDF = function* ({ data: { s_pdfFileContentInBase64 } }) {
  yield put(setDMPOtpPDF(s_pdfFileContentInBase64));
};
const setPatientAckPDF = function* ({ data: { s_pdfFileContentInBase64 } }) {
  yield put(setDMPAckPDF(s_pdfFileContentInBase64));
};
const setPatientCreatePDF = function* ({ data: { s_pdfFileContentInBase64 } }) {
  yield put(setDMPCreatePDF(s_pdfFileContentInBase64));
};
const emptyPDFs = function* () {
  yield put(resetPDFs());
};

const readVitaleCardAfterGetVitaleSuccess = function* ({ command: { s_sessionId }, context: { autoRead } }) {
  if (autoRead === true) {
    yield put(readVitaleProcess(
      s_sessionId,
      apiSections.VITALE_CARD_SECTION,
    ));
  }
};

const getVitaleXmlSurcharged = ({ dmpConnectPersistedAppConfiguration }) => ({
  vitaleXmlContent: getConfigurationValue('vitaleXmlContent', dmpConnectPersistedAppConfiguration),
  vitaleXmlType: getConfigurationValue('vitaleXmlType', dmpConnectPersistedAppConfiguration),
});

const readVitaleCardProcess = function* () {
  const { vitaleXmlContent, vitaleXmlType } = yield select(getVitaleXmlSurcharged);
  const readerName = yield select(getReaderName, 'vitaleCardReader');

  if (vitaleXmlContent) {
    yield put(getAction(
      commands.setVitaleXmlContent,
      'vitaleXmlContent',
      {
        s_xmlContent: vitaleXmlContent,
        i_cardType: vitaleXmlType || 3,
      },
    ));
    const setXml = yield take(
      [
        dmpCommandSuccessContextualizedType('vitaleXmlContent'),
        dmpCommandFailureContextualizedType('vitaleXmlContent'),
      ],
    );
    if (setXml.type === dmpCommandFailureContextualizedType('vitaleXmlContent')) return;
  }

  if (readerName.indexOf('WIRELESS') !== -1) {
    const jwtAccessToken = yield select(
      ({ dmpconnectUser: { userJWT } = {} }) => (userJWT ? userJWT.access_token : undefined),
    );
    const cpxLoginToken = yield select(state => state.dmpConnectPersistedAppConfiguration.cpxLoginToken);
    yield put(getAction(
      commands.updateVitaleAuthenticationToken,
      'updateVitaleAuthenticationToken',
      {
        s_authenticationToken: cpxLoginToken || jwtAccessToken,
      },
    ));
    const setToken = yield take(
      [
        dmpCommandSuccessContextualizedType('updateVitaleAuthenticationToken'),
        dmpCommandFailureContextualizedType('updateVitaleAuthenticationToken'),
      ],
    );
    if (setToken.type === dmpCommandFailureContextualizedType('updateVitaleAuthenticationToken')) return;
  }

  yield put(clearSection(apiSections.VITALE_CARD_SECTION));
  yield put(clearSection(apiSections.VITALE_DATA_SECTION));
  yield put(clearSection(apiSections.INS_FROM_VITALE_CARD));
  yield put(clearSection(apiSections.CERTIFIFIED_IDENTITY_SECTION));
  yield put(getAction(
    commands.readVitaleCard,
    apiSections.VITALE_CARD_SECTION,
    null,
  ));
};

const handleRestExportCdaContent = function* (action) {
  const { uniqueUUid, ins, content } = action;
  const { uniqueId, uuid } = explodeUniqueUUid(uniqueUUid);
  let cdaContent;

  if (content) {
    cdaContent = content;
  } else {
    cdaContent = yield select(getDocumentCdaContent, uniqueUUid);

    if (!cdaContent) {
      yield put(getAction(
        commands.getDocumentContentByUniqueId,
        apiSections.DOCUMENT_CONTENT_SECTION,
        {
          ...formatGetDocumentContentByUniqueIdParams(ins, uniqueId, uuid),
          i_getCdaContent: 1,
        },
        {
          subSection: uniqueUUid,
        },
      ));

      yield take(dmpCommandSuccessContextualizedType(apiSections.DOCUMENT_CONTENT_SECTION));
      cdaContent = yield select(getDocumentCdaContent, uniqueUUid);
    }
  }

  yield put(getAction(
    commands.exportData,
    apiSections.EXPORT_CDA_DOCUMENT,
    { s_contentInBase64: cdaContent },
    {
      subSection: uniqueUUid,
      contextExtra: { ins },
      silentError: true,
    },
  ));

  const result = yield take([
    dmpCommandSuccessContextualizedType(apiSections.EXPORT_CDA_DOCUMENT),
    dmpCommandFailureContextualizedType(apiSections.EXPORT_CDA_DOCUMENT),
  ]);

  if (result.type === dmpCommandFailureContextualizedType(apiSections.EXPORT_CDA_DOCUMENT)) {
    if (result.data.s_apiErrorExtendedInformations.toLowerCase().indexOf('unknown command') > -1) {
      yield put(getAction(
        commands.exportCdaDocument,
        apiSections.EXPORT_CDA_DOCUMENT,
        { s_cdaContentInBase64: cdaContent },
        {
          subSection: uniqueUUid,
          contextExtra: { ins },
        },
      ));
    } else {
      yield call(manageDmpconnectErrors, result);
    }
  }
  // }
};

const sendLoggedInNotificationToIframe = function* () {
  const { accessRights: { psId, authenticationType } } = yield select(getAccessRightsProps);
  const definedPracticeLocation = yield select(extractPracticeLocationFromState, psId);
  const esUser = yield select(getEsUser);

  let hpInfos;

  if (authenticationType === authenticationTypes.DIRECT) {
    hpInfos = {
      rpps: psId,
      ...definedPracticeLocation,
    };
  } else {
    hpInfos = esUser;
  }

  postMessageToIframeParent({
    type: 'login',
    desc: 'User has been logged in.',
    hpInfos,
  });
};

const resetAllConfigHandler = function* () {
  yield put(resetGlobalConfiguration(connectorConfigFromEnv));
  yield put(resetESConfiguration(esConfigFromEnv));
  yield put(resetDpConfiguration(dpConfigFromEnv));
  yield put(resetINSiConfiguration(insiConfigFromEnv));
  yield put(resetALDiConfiguration(aldConfigFromEnv));
  yield put(resetApCvConfiguration(apCvConfigFromEnv));
  yield put(resetMssConfiguration(mssConfigFromEnv()));
  yield put(resetPersistedAppConfigurationFromEnv());
  yield put(resetOpenIDConfigFromEnv());
  yield put(setPersistedConnectorConfiguration('onlyGeneratedConnectorJWT', Number(env.REACT_APP_GENERATED_CONNECTOR_JWT) === 1));
  yield put(setPersistedConnectorConfiguration('esRestVitale', Number(env.REACT_APP_ES_REST_ENABLE_VITALE_CARD_READING) === 1));
};

export const handleCallbacks = function* () {
  yield takeEvery(dmpCommandSuccessContextualizedType(apiSections.VITALE_SECTION), readVitaleCardAfterGetVitaleSuccess);
  yield takeEvery(dmpCommandSuccessContextualizedType(apiSections.EDIT_DMP_SECTION), refreshAdministrativeDataAfterUpdate);
  yield takeEvery(dmpCommandSuccessContextualizedType(apiSections.DMP_ACCESS_MODE_SECTION), handleRedirectAfterAccessModeSuccess);
  yield takeEvery([
    dmpCommandSuccessContextualizedType(apiSections.UPDATE_DOCUMENT_STATUS_SECTION),
    dmpCommandSuccessContextualizedType(apiSections.UPDATE_DOCUMENT_VISIBILITY_SECTION),
  ],
  refreshDocumentsAfterDocumentUpdate);
  yield takeEvery([
    dmpCommandSuccessContextualizedType(apiSections.SEND_DOCUMENT_SECTION),
    dmpRemoteCommandSuccessContextualizedType(apiSections.REMOTE_SEND_DOCUMENT),
  ], refreshDocuments);
  yield takeEvery([
    dmpconnectUserActionConstants.DMPC_SET_FIND_DOCUMENTS_PARAM,
    dmpconnectUserActionConstants.DMPC_SET_FIND_DOCUMENTS_PARAMS,
  ],
  refreshDocumentsAfterParamsChanged);
  yield takeEvery(dmpCommandSuccessContextualizedType(apiSections.FIND_DOCUMENTS_SECTION), handleGetAndParseLastHR);
  yield takeEvery(dmpCommandSuccessContextualizedType(apiSections.FIND_DOCUMENTS_SECTION), checkAndUpdatePreviousVersions);
  yield takeEvery(dmpconnectActionConstants.DMPC_GET_WEB_PS_URLS, setRootUrlAndrequestWebPsUrls);
  yield takeEvery(dmpconnectActionConstants.DMPC_CANCEL_COMMAND, handleCancelCommand);
  yield takeEvery(dmpCommandSuccessContextualizedType(apiSections.SESSION_STATE_SECTION), getCpxType);
  yield takeEvery([
    dmpCommandSuccessContextualizedType(apiSections.DELETE_DOCUMENT_SECTION),
    dmpRemoteCommandSuccessContextualizedType(apiSections.REMOTE_DELETE_DOCUMENT),
  ], refreshDocumentsAfterDelete);
  yield takeEvery(dmpconnectActionConstants.DMPC_REMOVE_DOC_FROM_PERISTANT_DATA, handleDeletePersistedDoc);
  yield takeEvery(dmpconnectActionConstants.DMPC_SEND_DOC_TO_PERISTANT_DATA, handleSentDocument);
  yield takeEvery([
    dmpCommandSuccessContextualizedType(apiSections.CLEAR_PERSISTANT_DATA),
    dmpCommandSuccessContextualizedType(apiSections.SET_PERSISTANT_DATA),
  ],
  refreshPersistantData);
  yield takeEvery(dmpconnectApplicationActionConstants.DMPC_DASHBOARD_INIT, dashboardInit);
  yield takeEvery(dmpconnectActionConstants.DMPC_GET_PERSISTANT_DATA_FROM_LOCALSTORAGE, getPersistantDataFromState);
  yield takeEvery(dmpconnectActionConstants.DMPC_SET_PERSISTANT_DATA_TO_LOCALSTORAGE, setPersistantDataToState);
  yield takeEvery(dmpconnectActionConstants.DMPC_CLEAR_PERSISTANT_DATA_FROM_LOCALSTORAGE, clearPersistantDataFromState);
  yield takeEvery(dmpconnectActionConstants.DMPC_REQUES_PCSC_READERS, handleRequestPcscReaders);
  yield takeEvery(dmpCommandSuccessContextualizedType(apiSections.PCSC_READERS_SECTION), handlePcsReadersSuccess);
  yield takeEvery(dmpconnectRemoteActionConstants.START_REMOTE_CONTROL_PROCESS, remoteMonitoringStartProcess);
  yield takeEvery(dmpconnectRemoteActionConstants.STOP_REMOTE_CONTROL_PROCESS, remoteMonitoringStopProcess);
  yield takeEvery([
    dmpCommandSuccessContextualizedType(apiSections.START_REMOTE_CONTROL),
    dmpCommandSuccessContextualizedType(apiSections.STOP_REMOTE_CONTROL),
    dmpCommandFailureContextualizedType(apiSections.START_REMOTE_CONTROL),
    dmpCommandFailureContextualizedType(apiSections.STOP_REMOTE_CONTROL),
  ], handleCheckRemoteControlStatus);
  yield takeEvery(dmpconnectRemoteActionConstants.DMPC_REMOTE_EXPORT_EMAIL, handleExportEmail);
  yield takeEvery(dmpCommandSuccessContextualizedType(apiSections.VITALE_CARD_SECTION), getVitaleCardXmlContent);
  // yield takeEvery(dmpCommandSuccessContextualizedType(apiSections.VITALE_CARD_SECTION), handleExportVitaleCardData);
  yield takeEvery(dmpCommandSuccessContextualizedType(apiSections.GET_APCV_CONTEXT), handleExportVitaleData);
  yield takeEvery(dmpconnectUserActionConstants.DMPC_LOGIN, handleLoginProcess);
  yield takeEvery(dmpconnectUserActionConstants.DMPC_LOGOUT, logoutProcess);
  yield takeEvery(dmpconnectUserActionConstants.DMPC_ES_LOGIN, handleEsLoginProcess);
  yield takeEvery(dmpconnectInitActionConstants.DMPC_INIT_APPLICATION_SUCCESS, initStatesAndMonitoring);
  yield takeEvery([
    dmpconnectUserActionConstants.DMPC_LOGIN_SUCCESS,
    dmpconnectUserActionConstants.DMPC_ES_LOGIN_SUCCESS,
  ], initVirtualPrinter);
  yield takeEvery([
    dmpconnectUserActionConstants.DMPC_LOGIN_SUCCESS,
    dmpconnectUserActionConstants.DMPC_ES_LOGIN_SUCCESS,
  ], sendLoggedInNotificationToIframe);
  yield takeEvery(dmpCommandSuccessContextualizedType(apiSections.GET_PATIENT_WEB_PDF_SECTION), setPatientWEBPDF);
  yield takeEvery(dmpCommandSuccessContextualizedType(apiSections.GET_PATIENT_ACK_PDF_SECTION), setPatientAckPDF);
  yield takeEvery(dmpCommandSuccessContextualizedType(apiSections.CREATE_DMP_SECTION), setPatientCreatePDF);
  yield takeEvery(dmpconnectUserActionConstants.DMPC_LOGOUT, emptyPDFs);
  yield takeEvery(dmpconnectApplicationActionConstants.DMPC_READ_VITALE_PROCESS, readVitaleCardProcess);

  yield takeEvery(dmpconnectInitActionConstants.DMPC_INIT_APPLICATION, checkMssLoginType);
  yield takeEvery([
    dmpCommandSuccessContextualizedType(apiSections.MSS_INIT_OTP_AUTH),
    dmpCommandSuccessContextualizedType(apiSections.MSS_PERFORM_AUTHENTICATION),
  ], setMssAuthenticationId);
  yield takeEvery(dmpCommandSuccessContextualizedType(apiSections.MSS_GET_FOLDERS), handleGetFoldersFromMssResult);
  yield takeEvery(dmpCommandSuccessContextualizedType(apiSections.MSS_SEARCH_MESSAGES), handleSearchMessagesFromMssResult);
  yield takeEvery(dmpCommandSuccessContextualizedType(apiSections.MSS_FULL_SEARCH_MESSAGES), handleFullSearchMessagesFromMssResult);
  yield takeEvery(dmpCommandSuccessContextualizedType(apiSections.MSS_SYNC_MESSAGES), handleSyncMessagesFromMssResult);
  yield takeEvery(dmpCommandSuccessContextualizedType(apiSections.MSS_UPDATE_MESSAGE), handleUpdateMessagesFromMssResult);
  yield takeEvery(dmpCommandSuccessContextualizedType(apiSections.MSS_DOWNLOAD_ATTACHMENT), handleDownloadAttachmentFromMssResult);
  yield takeEvery(mssActions.DOWNLOAD_ALL_ATTACHMENTS_ZIPPED, handleDownloadAndZipAllAttachments);
  yield takeEvery(mssActionConstants.SEND_WEB_EMAIL, handleSendMssWebEmail);
  yield takeEvery(dmpCommandSuccessContextualizedType(apiSections.MSS_SEND_SMTP_EMAIL), handleAppendMessageToSentDirectory);
  yield takeEvery(dmpCommandSuccessContextualizedType(apiSections.MSS_IMAP_APPEND_MESSAGE), handleRefreshSentFolder);
  yield takeEvery(mssActionConstants.IMAP_SYNC_MESSAGES, handleImapSyncMessages);
  yield takeEvery(dmpconnectConfigurationActionConstants.DMPC_SET_PERSIST_APP_MSS_SYNCMESSAGES, getPeopleInfosAfterReceiveMessages);
  yield takeEvery(dmpCommandSuccessContextualizedType(apiSections.MSS_GET_MESSAGE_CONTENT), handleGetMessageContentResult);
  yield takeEvery(mssActions.DELETE_MSS_FOLDER, handleDeleteMssFolder);
  yield takeEvery([
    dmpCommandSuccessContextualizedType(apiSections.MSS_DELETE_FOLDERS),
    dmpCommandSuccessContextualizedType(apiSections.MSS_RENAME_FOLDERS),
    dmpCommandSuccessContextualizedType(apiSections.MSS_CREATE_FOLDERS),
  ],
  handleRefreshFoldersList);
  yield takeEvery([
    dmpCommandSuccessContextualizedType(apiSections.MSS_DELETE_FOLDERS),
    dmpCommandSuccessContextualizedType(apiSections.MSS_RENAME_FOLDERS),
    dmpCommandSuccessContextualizedType(apiSections.MSS_CREATE_FOLDERS),
    dmpCommandFailureContextualizedType(apiSections.MSS_DELETE_FOLDERS),
    dmpCommandFailureContextualizedType(apiSections.MSS_RENAME_FOLDERS),
    dmpCommandFailureContextualizedType(apiSections.MSS_CREATE_FOLDERS),
  ],
  handleDirectoryManagementResult);
  yield takeEvery('SET_MSS_PSC_TOKEN', setMssPscAccessToken);
  yield takeEvery(dmpconnectActionConstants.DMPC_READ_CPX_PROCESS, handleReadCpxCard);
  yield takeEvery(dmpconnectApplicationActionConstants.DMPC_EXPORT_CDA_CONTENT, handleRestExportCdaContent);
  yield takeEvery(dmpconnectRemoteActionConstants.DMPC_REMOTE_EXPORT_DOCUMENT, handleExportDocument);

  // yield takeEvery(dmpCommandSuccessContextualizedType(apiSections.DOCUMENT_CONTENT_SECTION), saveStatInPersistantData);

  yield takeEvery(dmpconnectConfigurationActionConstants.DMPC_RESET_ALL_CONFIGURATION_FROM_ENV, resetAllConfigHandler);

  // APCV AUTO ACTIVATION APRES LA DATE CONFIGUREE
  yield takeEvery(dmpconnectInitActionConstants.DMPC_INIT_APPLICATION, forceApCvActivationAfterStartDate);

  // userPreferences
  // yield takeEvery(dmpconnectConfigurationActionConstants.SET_USER_PREFERENCE, setUserPreferenceInPersistantData);
  // dmpConnectCPxConfig
  // yield takeEvery(dmpconnectConfigurationActionConstants.DMPC_SET_USER_CONFIGURATION, setUserConfigurationInPersistantData);

  yield takeEvery(dmpconnectApplicationActionConstants.HARD_RESET, hardResetApplication);
};
