import React from 'react';
import { connect } from 'react-redux';
import * as PropTypes from 'prop-types';
import { encodeIns } from 'dmpconnectjsapp-base/utils/insUtils';
import { b64DecodeUnicode } from 'dmpconnectjsapp-base/utils/dataUtils';
import rename from 'deep-rename-keys';
import { apiSections } from 'dmpconnectjsapp-base/constants';
import {
  resetMssEmailContent, setMssEmailContent, setMssPscToken, setPinCode, setShowMssPopup, setUserJWT, setUserConfiguration,
} from '../../dmpconnect/actions';
import { receivedJSONRemoteRequest, receivedRemoteRequest } from '../../dmpconnect/actions/dmpconnectRemoteActions';
import { generateRemoteFailureResponse, remoteFormats, remoteSources } from '../../dmpconnect/helpers/remote';
import env from '../../envVariables';
import { postMessageToIframeParent } from '../../dmpconnect/utils/iframe';
import { errorTypes } from '../../dmpconnect/errors/errorConfiguration';
import userManager from '../../dmpconnect/constants/userManager';

const IframeMessageReceiver = ({
  dispatch, remoteFormat, cpxHpInfos,
}) => {
  React.useEffect(() => {
    const handler = (event) => {
      // vérification de l'origine de l'évènement
      const configTargetOrigin = env.REACT_APP_MESSAGE_EVENT_TARGET_ORIGIN || '*';

      if (configTargetOrigin === '*' || event.origin === configTargetOrigin) {
        try {
          const data = JSON.parse(event.data);
          console.log('incomingMessage_messageEvent', data);
          const {
            Mail,
            pinCode,
            practiceLocation,
            remoteCommand,
            mssPscToken,
          } = data;
          if (remoteCommand) {
            const command = b64DecodeUnicode(remoteCommand);
            console.log('incomingMessage_messageEvent decoded', command);

            if (remoteFormat === remoteFormats.JSON) {
              try {
                const parsed = JSON.parse(command);
                dispatch(receivedJSONRemoteRequest(parsed, remoteSources.IFRAME));
              } catch (e) {
                // reply invalid JSON
                const response = generateRemoteFailureResponse(
                  undefined,
                  {
                    i_apiErrorType: errorTypes.RemoteErrors,
                    i_apiErrorCode: 'INVALID_FORMAT',
                    s_apiErrorExtendedInformations: `${remoteFormat} invalide`,
                  },
                  [
                    {
                      parsingError: e.message,
                      receivedContent: command,
                    },
                  ],
                );
                postMessageToIframeParent({ answer: rename(response, key => key.replace(/^@/, '')) });
              }
            } else {
              try {
                dispatch(receivedRemoteRequest(command, remoteSources.IFRAME));
              } catch (e) {
                const response = generateRemoteFailureResponse(
                  undefined,
                  {
                    i_apiErrorType: errorTypes.RemoteErrors,
                    i_apiErrorCode: 'INVALID_FORMAT',
                    s_apiErrorExtendedInformations: `${remoteFormat} invalide`,
                  },
                  [
                    {
                      parsingError: e.message,
                      receivedContent: command,
                    },
                  ],
                );
                postMessageToIframeParent({ answer: response });
              }
            }
          }
          if (Mail) {
            dispatch(resetMssEmailContent(data.Mail.attachments.length > 0));
            dispatch(setMssEmailContent({
              recipients: data.Mail.recipients,
              cc: data.Mail.cc,
              bcc: data.Mail.bcc,
              title: data.Mail.title,
              messageContent: data.Mail.messageContent,
              isHtml: data.Mail.isHtml,
              attachments: data.Mail.attachments.map(att => ({
                patientIns: att.s_patientIns,
                fileContentInBase64: att.s_fileContentInBase64,
                documentTitle: att.s_documentTitle,
                documentDescription: att.s_documentDescription,
                documentCategory: att.s_documentCategory,
                documentFormat: att.i_documentFormat,
                healthcareSetting: att.s_healthcareSetting,
              })),
            }));
            dispatch(setShowMssPopup(true));
          }

          if (practiceLocation) {
            const {
              rpps,
              cpxPracticeLocationIndice = undefined,
              practiceSetting,
              healthcareSetting,
              billingNumber,
              customPracticeLocation: {
                structureName,
                structureId,
                structureIdType,
                activitySector,
              } = {},
            } = practiceLocation;

            const cpxInfos = Object.values(cpxHpInfos).find(info => info.s_idNatPs === rpps);

            if (cpxInfos) {
              let cpxConfig = {
                practiceSetting,
                healthcareSetting,
                billingNumber,
                codeSpeAMO: '99', // Non définie (99) par défaut
                fromRemote: true,
                forcedCustomPracticeLocation: false,
              };

              if (Number(cpxPracticeLocationIndice) >= 0) {
                cpxConfig = {
                  ...cpxConfig,
                  customPracticeLocationStructureName: null,
                  customPracticeLocationStructureIdType: null,
                  customPracticeLocationStructureId: null,
                  customPracticeLocationActivitySector: null,
                  practiceLocationSetting: Number(cpxPracticeLocationIndice),
                };
              } else {
                cpxConfig = {
                  ...cpxConfig,
                  customPracticeLocationStructureName: structureName,
                  customPracticeLocationStructureIdType: structureIdType,
                  customPracticeLocationStructureId: structureId,
                  customPracticeLocationActivitySector: activitySector,
                  practiceLocationSetting: null,
                };
              }

              dispatch(setUserConfiguration(cpxInfos.s_idNatPs, cpxConfig));
            } else {
              postMessageToIframeParent({
                type: 'practice_location_error',
                error: 'no card matches the given rpps',
              });
              throw new Error();
            }
          }

          if (pinCode) {
            dispatch(setPinCode(null));
            dispatch(setPinCode(encodeIns(pinCode)));
          }

          if (mssPscToken) {
            dispatch(setUserJWT(mssPscToken));
            dispatch(setMssPscToken(mssPscToken));

            userManager.loggedIn = true;
            userManager.user = { token: mssPscToken };
          }
        } catch (e) {}
      } else {
        console.log('incomingMessage_messageEvent blocked due to unknown origin', event);
      }
    };

    window.addEventListener('message', handler);

    // clean up
    return () => window.removeEventListener('message', handler);
  });
  return null;
};
IframeMessageReceiver.propTypes = {
  dispatch: PropTypes.func.isRequired,
  remoteFormat: PropTypes.string.isRequired,
  cpxHpInfos: PropTypes.object,
};
IframeMessageReceiver.defaultProps = {
  cpxHpInfos: {},
};

function mapStateToProps(state) {
  const {
    dmpConnectPersistedAppConfiguration: {
      remoteFormat = remoteFormats.XML,
    },
    dmpconnect: {
      [apiSections.CPX_HP_INFOS_SECTION]: cpxHpInfos,
    },
  } = state;
  return {
    remoteFormat,
    cpxHpInfos,
  };
}

export default connect(mapStateToProps)(IframeMessageReceiver);
