import React from 'react';
import PropTypes from 'prop-types';
import ReactJson from 'react-json-view';
import { b64DecodeUnicode } from '../../../dmpconnect/utils/dataUtils';

export default function ErrorDetails({ details = [] }) {
  const errorDetails = details.reduce((result, detail) => {
    const { content } = detail;

    let tmpResult = { ...result, [detail.name]: content };

    if (content.detailsType === 'error') {
      Object.entries(content).forEach(([key, value]) => {
        if (key.indexOf('Base64') > -1) {
          tmpResult = {
            ...tmpResult,
            decoded: {
              ...tmpResult.decoded,
              [`${key}_decoded`]: b64DecodeUnicode(value),
            },
          };
        }
      });
    }

    return { ...tmpResult, [detail.name]: content };
  }, {});

  return (

    <ReactJson
      src={errorDetails}
      name="Details"
      collapsed={2}
      collapseStringsAfterLength={50}
      displayObjectSize={false}
      displayDataTypes={false}
    />
  );
}

ErrorDetails.propTypes = {
  details: PropTypes.array.isRequired,
};
