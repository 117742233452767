import mergeWith from 'lodash.mergewith';
import { dmpconnectFiltersActionConstants, dmpconnectUserActionConstants } from '../constants';
import env from '../../envVariables';

const initialState = {
  defaultDocumentsTypeCodes: env.REACT_APP_DOCUMENTS_TYPE_CODES,
};

const setUserFilter = (state, psId, filterKey, filterValue) => {
  const {
    usersFilters: {
      [psId]: userFilters,
      ...userFiltersRest
    } = {},
    [psId]: oldUserFilters,
    ...filters
  } = state;

  return {
    usersFilters: {
      [psId]: {
        ...userFilters || oldUserFilters,
        [filterKey]: filterValue,
      },
      ...userFiltersRest,
    },
    ...filters,
  };
};

export function dmpconnectFilters(state = initialState, action) {
  switch (action.type) {
    case dmpconnectUserActionConstants.DMPC_LOGOUT_SUCCESS:
    case dmpconnectUserActionConstants.DMPC_LOGIN_SUCCESS:
      return {
        ...state,
        availableDmpDate: undefined,
        availableDmpType: undefined,
        availableDmpIds: undefined,
      };
    case dmpconnectFiltersActionConstants.DMPC_SET_ALL_USERS_FILTERS:
      return mergeWith(
        {}, state, { usersFilters: action.filters },
        (a, b) => (Array.isArray(b) ? b : undefined),
      );
    case dmpconnectFiltersActionConstants.DMPC_SET_USER_FILTER:
      return setUserFilter(state, action.psId, action.filterKey, action.filterValue);
    case dmpconnectFiltersActionConstants.DMPC_SET_CURRENT_FILTER:
      if (action.filterValue === undefined) {
        return { ...state, [action.filterKey]: undefined };
      }
      return mergeWith(
        {}, state, { [action.filterKey]: action.filterValue },
        (a, b) => (Array.isArray(b) ? b : undefined),
      );
    default:
      return state;
  }
}
